import React from 'react';
import './styles/InviteOrganization.css';
import { useLocation } from 'react-router-dom'
import api from '/app/frontend/src/api';

const InviteOrganization = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const location = useLocation();
    const token = localStorage.getItem('token');

    const handleAcceptInviteorganization = () => {
        const trimmedUrl = location.pathname.endsWith('/') ? location.pathname.slice(0, -1) : location.pathname;
        const parts = trimmedUrl.split('/');
        const hash = parts[parts.length - 1];
        const organizationId = parts[parts.length - 2];
        api.patch(`${apiUrl}api/v1/organizations/${organizationId}/accept-invite-organization/`, {
            'hash': hash,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
        if (response.status === 200) { 
            alert("приглашение принято, вы можете сменить организацию в профиле.");
            window.location.href = '/main';
        }}).catch(error => {
            const status = error.response ? error.response.status: null;
            if (error.response.status === 403 && error.response.data.detail === 'LinkNoActive')
            {
                alert(`Код ошибки: ${status}, Недействительная ссылка.`, error);
            }
            if (error.response.status === 403 && error.response.data.detail === 'UserExistsInThisOrganization')
            {
                alert(`Код ошибки: ${status}, Пользователь уже состоит в текущей организации.`, error);
            }
            if (error.response.status === 404 && error.response.data.detail === 'Not Found')
            {
                alert(`Код ошибки: ${status}, Организация не найдена.`, error);
            }
        });
    };

    var screenHeight = window.innerHeight;

    document.title = "Приглашение в организацию - ТвояЗадача";

    return (
        <div className="invite-organization-page" style={{ height: screenHeight - 140, width: "100%", justifyContent: "center" }}>
            <div id="div-for-accept-invite-organization">
                <button id="button-accept-invite-organization"
                    onClick={() => handleAcceptInviteorganization()}
                >
                    Принять приглашение
                </button>
            </div>
        </div>
    );
}

export default InviteOrganization;