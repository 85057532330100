
import React, { useEffect, useState, useRef } from 'react';
import './styles/TasksList.css';
import Modal from 'react-modal';
import Calendar from 'react-calendar';
import api from '/app/frontend/src/api';
import { format } from 'date-fns';
import CustomSelect from './components/CustomSelect';
import { Tooltip } from 'react-tooltip';

const TasksList = () => {
    Modal.setAppElement('#root');
    const timerRef = useRef(null);
    const apiUrl = process.env.REACT_APP_API_URL;
    const apiUrlFrontend = process.env.REACT_APP_FRONTEND_URL;
    const token = localStorage.getItem('token');
    const [task, setTask] = useState([]);
    const [label, setLabel] = useState([]);
    const [performer, setPerformer] = useState([]);
    const [group, setGroup] = useState([]);
    const [priority] = useState([1,2,3,4,5,6,7,8,9,10]);
    const [filteredTasks, setFilteredTask] = useState([]);
    const [searchItem, setSearchItem] = useState("");
    const [isTextSelected, setIsTextSelected] = useState(false);
    const [dateRange, setDateRange] = useState([null, null]);
    const [dateRangedeadline, setDateRangedeadline] = useState([null, null]);
    const [modalIsOpenCalendar, setIsOpenCalendar] = useState(false);
    const [modalIsOpenCalendardeadline, setIsOpenCalendardeadline] = useState(false);
    const [board, setBoard] = useState([]);
    const [openModalChangeGroupTask, setopenModalChangeGroupTask] = useState(false);
    const [openModalChangeBoard, setopenModalChangeBoard] = useState(false);
    const [boardid, setboardid] = useState("");
    const page = useState(1);
    const [loadingTask, setLoadingTask] = useState(true);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [myProfile, setMyProfile] = useState([]);
    const [privatboard, setPrivateBoard] = useState(false);
    const [canAddTask, setCanAddTask] = useState(false);
    const [changegroup, setchangegroup] = useState([]);
    const [allowCreateTaskInStatusCompleted, setAllowCreateTaskInStatusCompleted] = useState(false);
    const [selectedDefaultFilters, setSelectedDefaultFilters] = useState({
        label: [],
        performer: [],
        status: [],
        priority: [],
    });
    const [selectedFilters, setSelectedFilters] = useState({
        label: [],
        performer: [],
        status: [],
        priority: [],
        title: '',
        datecreatestart: '',
        datecreateend: '',
        deadlinestart: '',
        deadlineend: '',
    });
    const [openModalDefaultFilter, setOpenModalDefaultFilter] = useState(false);

    useEffect(() => {
        const fetchFilteredData = async () => {
            if (selectedFilters.performer.length !== 0 ||
                selectedFilters.label.length !== 0 ||
                selectedFilters.status.length !== 0 ||
                selectedFilters.priority.length !== 0 ||
                selectedFilters.title.length !== 0 ||
                selectedFilters.deadlineend !== '' ||
                selectedFilters.deadlinestart !== '' ||
                selectedFilters.datecreateend !== '' ||
                selectedFilters.datecreatestart !== ''
                ) {
                    api.post(`${apiUrl}api/v1/tasks/get-filtered-task/`, { 
                        'performers': selectedFilters.performer.map(pf => pf.id),
                        'labels': selectedFilters.label.map(lb => lb.id),
                        'groups': selectedFilters.status.map(st => st.title),
                        'prioritys': selectedFilters.priority,
                        'title': selectedFilters.title,
                        'datecreatestart': selectedFilters.datecreatestart,
                        'datecreateend': selectedFilters.datecreateend,
                        'deadlinestart': selectedFilters.deadlinestart,
                        'deadlineend': selectedFilters.deadlineend,
                    },{
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                    }).then((response) => {
                        if (response.status === 200) {
                            setFilteredTask(response.data);
                            if (response.data.length === 0) {
                                setLoadingTask(false);
                            }
                        }
                    }).catch(error =>{
                        console.error(error);
                    });
            }
            else {
                api.get(`${apiUrl}api/v1/tasks/`, {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                }).then((response) => {
                    if (response.status === 200){
                        setFilteredTask(response.data);
                        setTask(response.data);
                        if (response.data.length === 0) {
                            setLoadingTask(false);
                        }
                    }
                }).catch(error =>{
                    console.error(error);
                });
            }
        };
        fetchFilteredData();
        // eslint-disable-next-line
    }, [selectedFilters]);

    useEffect(() => {
        if (filteredTasks.length > 0) {
            setLoadingTask(false);
        }
    }, [filteredTasks]);

    const handleFilterChange = (filterName, selectedOptions) => {
        setSelectedFilters(prevFilters => ({
          ...prevFilters,
          [filterName]: selectedOptions
        }));
    };

    const handleSetDefaultFilters = () => {
        api.patch(`${apiUrl}api/identity/auth/users/me/`, {
            'default_filters_for_task_list': selectedDefaultFilters
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) { 
                alert("Фильтры по умолчанию сохранены");
                closeModal();
                api.get(`${apiUrl}api/v1/tasks/`, {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                }).then((response) => {
                    if (response.status === 200){
                        setFilteredTask(response.data);
                        setTask(response.data);
                    }
                }).catch(error =>{
                    console.error(error);
                });
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleDeleteDefaultFilters = () => {
        setSelectedDefaultFilters({
            label: [],
            performer: [],
            status: [],
            priority: [],
        });
        api.patch(`${apiUrl}api/identity/auth/users/me/`, {
            'default_filters_for_task_list': []
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) { 
                alert("Фильтры по умолчанию сброшены");
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleDefaultFilterChange = (filterType, id) => {
        setSelectedDefaultFilters(prevFilters => {

            const currentFilterValues = prevFilters[filterType];
            const isChecked = currentFilterValues.includes(id);

            if (isChecked) {
                return {
                    ...prevFilters,
                    [filterType]: currentFilterValues.filter(existingId => existingId !== id),
                };
            } else {
                return {
                    ...prevFilters,
                    [filterType]: [...currentFilterValues, id],
                };
            }
        });
    }

    function closeModal() {
        setOpenModalDefaultFilter(false);
        setIsOpenCalendar(false);
        setIsOpenCalendardeadline(false);
        setopenModalChangeBoard(false);
        setopenModalChangeGroupTask(false);
    };

    const handleopenModalCalendar = () => {
        closeModal();
        setIsOpenCalendar(true);
    };

    const handleopenModalCalendardeadline = () => {
        closeModal();
        setIsOpenCalendardeadline(true);
    };

    useEffect(() => {
        const CheckPermission = async () => {
            await api.get(`${apiUrl}api/v1/user/permissions/`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    if (response.data.includes('Can add Задача')) {
                        setCanAddTask(true);
                    }
                    if (response.data.includes('owner_organization')) {
                        setCanAddTask(true);
                    }
                }
            }).catch(error => {
                console.log(error);
            });
        };
        CheckPermission();
        api.get(`${apiUrl}api/v1/labels/`,{
            headers: {
                 'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if(response.status === 200) {
                const uniqueLabels = response.data.filter((item, index) => {
                    return response.data.findIndex(obj => obj.name === item.name) === index;
                });
                setLabel(uniqueLabels);
            }
        }).catch(error =>{
            console.error(error);
        });
        api.get(`${apiUrl}api/v1/groups/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                const uniqueGroups = response.data.filter((item, index) => {
                    return response.data.findIndex(obj => obj.title === item.title) === index;
                });
                setGroup(uniqueGroups);
            }
        }).catch(error =>{
            console.error(error);
        });
        fetchAllUsers();
        api.get(`${apiUrl}api/identity/auth/users/me/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                if (response.data.default_filters_for_task_list.length !== 0) {
                    setSelectedDefaultFilters(response.data.default_filters_for_task_list);
                }
                setMyProfile(response.data);
            }
        }).catch(error =>{
            console.error(error);
        });
        // eslint-disable-next-line
    }, []);

    function fetchAllUsers(page = 1, allUsers = []) {
        api.get(`${apiUrl}api/identity/users/`, {
            params: { page: page },
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                const fetchedUsers = response.data;
                const updatedUsersList = allUsers.concat(fetchedUsers);
                if (response.data.next) {
                    fetchAllUsers(page + 1, updatedUsersList);
                } 
                else {
                    setPerformer(updatedUsersList);
                }
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleOpenModalDefaultFilter = (e) => {
        e.preventDefault();
        closeModal();
        setOpenModalDefaultFilter(true);
    };

    const handleOpenModalBoard = (event) => {
        event.preventDefault();
        closeModal();
        const loadBoards = async () => {
            if (loading || !hasMore) return;
            setLoading(true);
            try {
                const response = await api.get(`${apiUrl}api/v1/boards/`, {
                params: { page: page },
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            });
            if (response.status === 200) {
                setBoard(prevBoards => {
                    const newBoards = response.data.results.filter(newBoard => 
                    newBoard.id !== boardid && !prevBoards.some(prevBoard => prevBoard.id === newBoard.id));
                    return [...prevBoards, ...newBoards];
                });
                setHasMore(response.data.next != null);
            }} 
            catch (error) {
                console.error(error);
                const status = error.response ? error.response.status: null;
                if (status !== 403) {
                }
            }
        }
        loadBoards();
        setopenModalChangeBoard(true);
    };

    const handleOpenChangeGroup = (event, board) => {
        closeModal();
        if (board.private_board === true) {
            api.get(`${apiUrl}api/v1/groups/`, {
                params: {
                    'board_id': board.slug
                },
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                }).then((response) => {
                    if (response.status === 200) {
                        setchangegroup(response.data);
                        setopenModalChangeGroupTask(true);
                    }
                }).catch(error => {
                    if (error.response.status !== 403) {
                    }
            });
        }
        else {
            api.get(`${apiUrl}api/v1/groups/`, {
                params: {
                    'board_id': board.slug
                },
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                }).then((response) => {
                    if (response.status === 200) {
                        setchangegroup(response.data);
                    }
                }).catch(error => {
                    if (error.response.status !== 403) {
                    }
            });
            api.get(`${apiUrl}api/v1/organizations/`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    setAllowCreateTaskInStatusCompleted(response.data[0].allow_create_task_in_status_completed);
                    setopenModalChangeGroupTask(true);
                }
            }).catch(error =>{
                const status = error.response ? error.response.status: null;
                if (error.response.status === 403) {
                    alert(`Код ошибки: ${status}, ${error.response.data.detail}`, error);
                }
            });
        }
    };

    const handleDateChange = date => {
        if (dateRange[0] && dateRange[1]) {
            setDateRange([date, null]);
        } 
        else if (dateRange[0] == null) {
            setDateRange([date, null]);
        } 
        else {
            setDateRange(prevRange => [prevRange[0], date]);
            const currentformatdt0 = format(new Date(dateRange[0]), 'yyyy-MM-dd');
            const currentformatdt1 = format(new Date(date), 'yyyy-MM-dd');
            handleFilterChange('datecreatestart', currentformatdt0);
            handleFilterChange('datecreateend', currentformatdt1);
        }
    };

    const handleDateChangedeadline = date => {
        if (dateRangedeadline[0] && dateRangedeadline[1]) {
            setDateRangedeadline([date, null]);
        }
        else if (dateRangedeadline[0] == null) {
            setDateRangedeadline([date, null]);
        }
        else {
            setDateRangedeadline(prevRange => [prevRange[0], date]);
            const currentformatdt0 = format(new Date(dateRangedeadline[0]), 'yyyy-MM-dd');
            const currentformatdt1 = format(new Date(date), 'yyyy-MM-dd');
            handleFilterChange('deadlinestart', currentformatdt0);
            handleFilterChange('deadlineend', currentformatdt1);
        }
    };

    const handleTaskDetailGo = (event, task_id) => {
        event.preventDefault();
        window.location.href = '/task/' + task_id;
    };

    const handleTaskDetailGoNewPin = (e, task_id) => {
        if (e.button === 1) {
            e.preventDefault();
            window.open('/task/' + task_id);
        }
    };

    const handleClickGotoBoard = (id_task) => {
        api.get(`${apiUrl}api/v1/tasks/${id_task}/get-board-task/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                localStorage.setItem('titleboards', response.data.title);
                localStorage.setItem('slugboards', response.data.slug);
                localStorage.setItem('boardID', response.data.id);
                window.location.href = "/boards/" + response.data.slug;
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleClickGotoBoardNewPin = (id_task, e) => {
        if (e.button === 1) {
            e.preventDefault();
            api.get(`${apiUrl}api/v1/tasks/${id_task}/get-board-task/`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    localStorage.setItem('titleboards', response.data.title);
                    localStorage.setItem('slugboards', response.data.slug);
                    localStorage.setItem('boardID', response.data.id);
                    window.open("/boards/" + response.data.slug);
                }
            }).catch(error => {
                console.error(error);
            });
        }
    };

    const handleTextareachange = (event) => {
        setSearchItem(event.target.value);
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        timerRef.current = setTimeout(() => {
            handleFilterChange('title', event.target.value);
        }, 500);
    };

    const handleTextareaClick = (event) => {
        const textarea = event.target;
        if (!isTextSelected) {
            textarea.focus();
            textarea.setSelectionRange(0, textarea.value.length);
            setIsTextSelected(true);
        }
        else{
            setIsTextSelected(false);
        }
    };

    const handleResetFilter = () => {
        setSelectedFilters({
            'title': '',
            'datecreatestart': '',
            'performer': [],
            'label': [],
            'status': [],
            'priority': [],
            'datecreateend': '',
            'deadlinestart': '',
            'deadlineend': ''
        });
        setSearchItem('');
        // window.location.href = "/tasks/list/"
    };

    const handleRedirectToCreateTask = (event, item) => {
        event.preventDefault();
        localStorage.setItem('grouptaskcreate', item.title);
        localStorage.setItem('grouptasksystemstatus', item.system_status);
        localStorage.setItem('grouptaskidcreate', item.group_id);
        localStorage.setItem('relatedtaskid', '');
        window.location.href = "/create-task";
    };

    const [checkTypeSortCreatedTask, setCheckTypeSortCreatedTask] = useState(false);
    const [checkTypeSortDeadlineTask, setCheckTypeSortDeadlineTask] = useState(false);
    const handleSortedDateTopBottom = (type) => {
        if (type === 'created_at') {
            setFilteredTask(filteredTasks.slice().sort((a, b) => {
                const dateA = Date.parse(a.created_at);
                const dateB = Date.parse(b.created_at);
                return checkTypeSortCreatedTask ? dateB - dateA : dateA - dateB;
            }));
            setCheckTypeSortCreatedTask(!checkTypeSortCreatedTask);
        }
        else {
            setFilteredTask(filteredTasks.slice().sort((a, b) => {
                const dateA = Date.parse(a.deadline) || Infinity;
                const dateB = Date.parse(b.deadline) || Infinity;
                return checkTypeSortDeadlineTask ? dateB - dateA : dateA - dateB;
            }));
            setCheckTypeSortDeadlineTask(!checkTypeSortDeadlineTask);
        }
    };

    var screenHeight = window.innerHeight;
    var screenWidth = window.innerWidth;

    document.title = "Список задач - ТвояЗадача";

    if (loadingTask) {
        return  (
            <div className="maindivfortaskslist"
            >
                <div style={{minWidth: "100%", minHeight: "100vh", justifyContent: "center", alignItems: "center", display: "flex"}}>
                    <img style={{maxHeight: "250px", maxWidth: "180px", borderRadius: "30px"}}
                        alt=""
                        src={apiUrlFrontend + 'loader.gif'}
                    >
                    </img>
                </div>
            </div>
        )
    }

    return (
        <div className="maindivfortaskslist"
        >
            <Modal className="Modal"
                isOpen={openModalDefaultFilter}
                onRequestClose={closeModal}
                overlayClassName="Overlay"
            >
                <div id="div-for-default-filter">
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <label id="main-label-for-default-filter">
                            Выберите стандартные настройки фильтрации
                        </label>
                        <button id="close-modal-default-filter-task-list" onClick={closeModal}>
                            <img src={apiUrlFrontend + "close-btn.svg"} alt=""></img>
                        </button>
                    </div>
                    <div id='div-with-column-filters'>
                        {label.length > 0 ?
                        <div>
                            <label id="name-column-default-filter">
                                Метка
                            </label>
                            <div id="default-filters-column">
                            {label.map((lb, lbindex) => (
                            <div style={{display: 'flex'}}>
                                <label id="label-for-default-filter-name">{lb.name}</label>
                                <label class="checkbox style-e" key={lbindex}>
                                    <input id="check-group"
                                        type="checkbox"
                                        onChange={() => handleDefaultFilterChange('label', lb.id)}
                                        checked={selectedDefaultFilters.label.includes(lb.id)}
                                    />
                                    <div class="checkbox__checkmark"></div>
                                </label>
                            </div>
                            ))}
                        </div>
                        </div>: null}
                        {performer.length > 0 ?
                        <div>
                            <label id="name-column-default-filter">
                                Исполнитель
                            </label>
                            <div id="default-filters-column">
                                {performer.map((pf, pfindex) => (
                                <div style={{display: 'flex'}}>
                                    {pf.formated_fio ?
                                        <label id="label-for-default-filter-name">{pf.formated_fio}</label>:
                                        <label id="label-for-default-filter-name">{pf.username}</label>
                                    }
                                    <label class="checkbox style-e" key={pfindex}>
                                        <input id="check-group"
                                            type="checkbox"
                                            onChange={() => handleDefaultFilterChange('performer', pf.id)}
                                            checked={selectedDefaultFilters.performer.includes(pf.id)}
                                        />
                                        <div class="checkbox__checkmark"></div>
                                    </label>
                                </div>
                                ))}
                            </div>
                            </div>: null}
                        {group.length > 0 ?
                        <div>
                            <label id="name-column-default-filter">
                                Группа
                            </label>
                            <div id="default-filters-column">
                                {group.map((gp, gpindex) => (
                                <div style={{display: 'flex'}}>
                                    <label id="label-for-default-filter-name">{gp.title}</label>
                                    <label class="checkbox style-e" key={gpindex}>
                                        <input id="check-group"
                                            type="checkbox"
                                            onChange={() => handleDefaultFilterChange('status', gp.title)}
                                            checked={selectedDefaultFilters.status.includes(gp.title)}
                                        />
                                        <div class="checkbox__checkmark"></div>
                                    </label>
                                </div>
                                ))}
                            </div>`
                        </div>: null}
                        <div>
                            <label id="name-column-default-filter">
                                Приоритет
                            </label>
                            <div id="default-filters-column">
                                {priority.map((pr, prindex) => (
                                <div style={{display: 'flex'}}>
                                    <label id="label-for-default-filter-name">{pr}</label>
                                    <label class="checkbox style-e" key={prindex}>
                                        <input id="check-group"
                                            type="checkbox"
                                            onChange={() => handleDefaultFilterChange('priority', pr)}
                                            checked={selectedDefaultFilters.priority.includes(pr)}
                                        />
                                        <div class="checkbox__checkmark" style={{marginLeft: "10px"}}></div>
                                    </label>
                                </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div id="div-bottom-button-default-filter">
                    <button id="reset-default-filter-button"
                            onClick={() => handleDeleteDefaultFilters()}
                        >
                            Сбросить
                        </button>
                        <button id="accept-default-filter-button"
                            onClick={() => handleSetDefaultFilters()}
                        >
                            Применить
                        </button>
                        
                    </div>
                </div>
            </Modal>
            <Modal
                className="Modal"
                isOpen={openModalChangeBoard}
                onRequestClose={closeModal}
                overlayClassName="Overlay"
            >   
                <div id="div-select-board-div">
                    {/* <button id="CloseModalTask" onClick={closeModal}>X</button> */}
                    <label id="select-board-label-task">Выберите доску</label>
                    <div id="scroll-Y-div">
                        {board.length > 0 ? board.map((item, index) => (
                            <button id="select-board-task" key={index} onClick={(event) => handleOpenChangeGroup(event, item)}>
                                {item.title}
                            </button>
                        )): null}
                    </div>
                </div>
            </Modal>
            <Modal
                className="Modal"
                isOpen={openModalChangeGroupTask}
                onRequestClose={closeModal}
                overlayClassName="Overlay"
            >   
                <div id="div-select-board-div">
                    {/* <button id="CloseModalTask" onClick={closeModal}>X</button> */}
                    <label id="select-board-label-task">Выберите группу</label>
                    <div id="scroll-Y-div">
                        {allowCreateTaskInStatusCompleted ? 
                        changegroup.length > 0 ? changegroup.map((item, index) => (
                            <button id="select-board-task" key={index} onClick={(event) => handleRedirectToCreateTask(event, item)}>
                                {item.title}
                            </button>
                        )): null: 
                        changegroup.length > 0 ? changegroup.map((item, index) => (
                            item.system_status_name !== 'Completed' ? 
                            <button id="select-board-task" key={index} onClick={(event) => handleRedirectToCreateTask(event, item)}>
                                {item.title}
                            </button>: null
                        )): null}
                    </div>
                </div>
            </Modal>
            <div id="divforfilter">
                <div id="search-and-reset">
                    <input id="search" style={{outline: 'none'}}
                        placeholder="Поиск"
                        onClick={handleTextareaClick}
                        value={searchItem}
                        onChange={handleTextareachange}
                    >
                    </input>
                    <button id="button-reset-filter"
                        onClick={handleResetFilter}
                    >
                        <img
                        id="reset-icon"
                        src={apiUrlFrontend + "reset-btn.svg"}
                        alt=""
                    >
                    </img>
                    </button>
                </div>
                <CustomSelect
                    name="label" 
                    options={label}
                    onFilterChange={(selected) => handleFilterChange('label', selected)}
                />
                <CustomSelect
                    name="performer"
                    options={performer}
                    onFilterChange={(selected) => handleFilterChange('performer', selected)}
                />
                <CustomSelect
                    name="status"
                    options={group}
                    onFilterChange={(selected) => handleFilterChange('status', selected)}
                />
                <div id="open-calendar">
                    <button id="open-calendar-btn"
                        onClick={handleopenModalCalendardeadline}
                    >
                        Дата создания
                    </button>
                    <img style={{width: "20px", height: "20px", marginLeft: "auto"}}
                        onClick={() => handleSortedDateTopBottom("created_at")}
                        alt=""
                        src={apiUrlFrontend + "btn-sort.svg"}
                    >
                    </img>
                </div>
                <div id="open-calendar">
                    <button id="open-calendar-btn"
                        onClick={handleopenModalCalendardeadline}
                    >
                        Дата сдачи
                    </button>
                    <img style={{width: "20px", height: "20px", marginLeft: "auto"}}
                        onClick={() => handleSortedDateTopBottom("deadline")}
                        alt=""
                        src={apiUrlFrontend + "btn-sort.svg"}
                    >
                    </img>
                </div>
                <CustomSelect
                    name="priority" 
                    options={priority}
                    onFilterChange={(selected) => handleFilterChange('priority', selected)}
                />
                <img
                    onClick={(e) => handleOpenModalDefaultFilter(e)}
                    id="settings-icon"
                    src={apiUrlFrontend + "settings.svg"}
                    alt=""
                >
                </img>
                {canAddTask ?
                <img
                    onClick={(e) => handleOpenModalBoard(e)}
                    id="add-task-icon"
                    src={apiUrlFrontend + "add-task-in-task-list.svg"}
                    alt=""
                >
                </img>: null}
            </div>
            {filteredTasks.length > 0 ?
            <div id="divforlisttasks">
                {filteredTasks.map((task, index) => (
                    <div id="tasksdivlist" key={index}>
                        <button id="tasks"
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Перейти к задаче"
                            onClick={(event) => handleTaskDetailGo(event, task.task_id)}
                            onMouseDown={(e) => handleTaskDetailGoNewPin(e, task.task_id)}
                        >
                            {task.title}
                        </button>
                        <div id="divforalllabel">
                            {label.length > 0 ? task.label.map(lb => lb).slice(0, 3).map((label, labelIndex) => (
                                <div key={labelIndex} id="divforlabeltitle">
                                    <div id="circle"
                                        style={{background: label.color, marginRight: "5px"}}
                                    />
                                    {label.name.slice(0, 15)}
                                </div>
                            )):null}
                        </div>
                        <div id="divforallperformer">
                            {task.formated_performer_fio ?
                                task.formated_performer_fio.slice(0, 3).map((pf_fio, pf_fio_Index) => (
                                <div key={pf_fio_Index} id="divforperformerusername">
                                    <label id="labelwithperformerusername">{performer.length > 0 ? pf_fio.slice(0, 20): null}</label>
                                </div>
                                )):
                                task.performer.slice(0, 3).map((per_username, performerIndex) => (
                                    <div key={performerIndex} id="divforperformerusername">
                                        <label id="labelwithperformerusername">{performer.length > 0 ? per_username.slice(0, 20): null}</label>
                                    </div>
                                ))
                            }
                        </div>
                        <label id="grouptasklist">
                        {group.map(lt => lt.title).includes(task.group_name) ?
                            task.group_name.slice(0, 28): null}
                        </label>
                        <label id="datetimetaskslist">
                            {format(new Date(task.created_at), 'dd.MM.yyyy hh:mm')}
                        </label>
                        {task.deadline ?
                        <label id="deadlinetasklist" 
                            style={{ color: ((new Date(task.deadline.substring(0, 10)) - new Date()) / 86400000) <= 3 ?
                                    'rgba(175, 52, 52, 1.00)': ((new Date(task.deadline.substring(0, 10)) - new Date()) / 86400000) <= 7 ? 
                                    'rgba(220, 136, 0, 1.00)': 'rgba(100, 120, 214, 1.00)', fontWeight: 'bold'}}
                        >
                            {format(new Date(task.deadline), 'dd.MM.yyyy')}
                        </label>: <label id="deadlinetasklist" 
                            style={{fontWeight: 'bold', color: 'rgb(63, 127, 232)'}}
                        >
                            Не назначен
                        </label>}
                        {task.priority ?
                        <label id="prioritytasklist"
                            style={{ color: task.priority <=3 ? 'rgba(100, 120, 214, 1.00)':
                            task.priority <=7 ? 'rgba(220, 136, 0, 1.00)':
                            'rgba(175, 52, 52, 1.00)'}}
                        >
                            {task.priority}
                        </label>: <label id="prioritytasklist"
                            style={{fontWeight: 'bold', fontSize: '18px', color: 'rgb(63, 127, 232)'}}
                        >
                            Нет
                        </label>}
                        <button id="go-to-board"
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={task.board_name}
                            onClick={() => handleClickGotoBoard(task.task_id)}
                            onMouseDown={(e) => handleClickGotoBoardNewPin(task.task_id, e)}
                        >
                            На доску
                        </button>
                    </div>
                ))}
            </div>: <div id="null-tasks" style={{height: screenHeight - 200}}>
                Задач нет, самое время их создать
            </div>
            }
             <Modal
                className="Modal-calendar"
                isOpen={modalIsOpenCalendar}
                onRequestClose={closeModal}
                overlayClassName="Overlay"
            >   
                <div id="div-calendar">
                    
                    <Calendar
                        value={dateRange}
                        onChange={handleDateChange}
                    />
                </div>
            </Modal>
            <Modal
                className="Modal-calendar"
                isOpen={modalIsOpenCalendardeadline}
                onRequestClose={closeModal}
                overlayClassName="Overlay"
            >      
                <div id="div-calendar">
                    
                        <Calendar  
                            value={dateRangedeadline}
                            onChange={handleDateChangedeadline}
                        />
                </div>
            </Modal>
            <Tooltip 
                id="my-tooltip"
                delayShow={750}
                delayHide={100}
            />
        </div>
        
        
    );
}

export default TasksList;