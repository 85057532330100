import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './styles/Header.css';
import Modal from 'react-modal';
import api from '/app/frontend/src/api';

const Header = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const apiUrlFrontend = process.env.REACT_APP_FRONTEND_URL;
    const location = useLocation();
    const token = localStorage.getItem('token');
    const [user, setUser] = useState('');
    const [boardname, setBoardName] = useState('');
    const [numberoftasks, setTasksnumber] = useState([]);
    const [author, setauthor] = useState('');
    const [checkRole, setCheckRole] = useState(false);
    const [checkPermission, setCheckPermission] = useState(false);
    const [addRole, setAddRole] = useState(false);
    const [modalIsOpenPhone, setModalIsOpenPhone] = useState(false);

    function closeModal() {
      setModalIsOpenPhone(false);
    };

    const handleGoToBoards = (event) => {
        window.location.href = "/main";
    };
    const handleGoToBoardsNewPin = (e) => {
      if (e.button === 1) {
        e.preventDefault();
        window.open("/main");
      }
    };
    const handleGoToAuth = (event) => {
        window.location.href = "/login";
    };
    const handleGoToRegist = (event) => {
      window.location.href = "/registration";
    };
    const handleGoToProfile = (event) => {
        window.location.href = "/profile/" + user.username;
    };
    const handleGoToProfileNewPin = (e) => {
      if (e.button === 1) {
        e.preventDefault();
        window.open("/profile/" + user.username);
      }
    };
    const handleGoToRevertBoard = (event) => {
        const slug_boards = localStorage.getItem('slugboards');
        window.location.href = `/boards/${slug_boards}`;
    };
    const handleGoToRevertBoardNewPin = (e) => {
      if (e.button === 1) {
        const slug_boards = localStorage.getItem('slugboards');
        window.open(`/boards/${slug_boards}`);
      }
  };
    const handleGoToTask = (event) => {
        window.location.href = "/tasks/list/";
    };
    const handleGoToTaskNewPin = (e) => {
      if (e.button === 1) {
        e.preventDefault();
        window.open("/tasks/list/");
      }
    };
    const handleGoToUnauth = () => {
        localStorage.setItem('token', '');
        localStorage.setItem('refreshToken', '');
        window.location.href = '/login';
    };

    function getnumbertask() {
        const split = (window.location.pathname).split('/');
        const task_pk = split[2];
        return (<label id="boardname">Задача {task_pk}</label>);
    };

    const handleGoToSettings = (event) => {
      window.location.href = "/settings";
    };
    const handleGoToSettingsNewPin = (e) => {
        if (e.button === 1) {
            e.preventDefault();
            window.open("/settings");
        }
    };
  
    function CheckAuth() {
        if (user !== '') {
            if (location.pathname.startsWith(`/profile/${user.username}`))
            {
              return(<button onClick={handleGoToUnauth} id="redirectProfile">Выйти</button>);
            }
            else if (location.pathname === '/')
            {
              return(<button onClick={() => window.location.href = '/main'} id="redirectProfile">{user.formated_fio ? user.formated_fio: user.username}</button>);
            }
            else {
              return(<button onMouseDown={(e) => handleGoToProfileNewPin(e)} onClick={handleGoToProfile} id="redirectProfile">{user.formated_fio ? user.formated_fio: user.username}</button>);
            }
        }
      else {
        return(
            <div id="div-with-auth-and-reg-button">
                <button onClick={handleGoToAuth} id='lol'>Войти</button>
                <button onClick={handleGoToRegist} id='lol' >Зарегистрироваться</button>
            </div>
        );
      }
    };

    const handleGoToTechnicalSupport = (event) => {
      window.location.href = "/main-info";
    };
    const handleGoToTechnicalSupportNewPin = (e) => {
        if (e.button === 1) {
            e.preventDefault();
            window.open("/main-info");
        }
    };

    React.useEffect(() => {
      const CheckPermission = async () => {
        await api.get(`${apiUrl}api/v1/user/permissions/`, {
          headers: {
              'Authorization': 'Bearer ' + token
          },
        }).then((response) => {
          if (response.status === 200) {
              if (location.pathname.startsWith('/create-task')){
                if (response.data.includes('owner_organization') || response.data.includes('Can add Задача')) {
                }
                else{
                  window.location.href = '/main';
                }
              }
              if (location.pathname.startsWith('/settings')){
                if (response.data.includes('owner_organization') || response.data.includes('Can view group')
                  || (response.data.includes('Can add group') && response.data.includes('Can view permission'))
                  ) {}
                else{
                  window.location.href = '/main';
                }
              }
              if (response.data.includes('owner_organization')){
                setCheckRole(true);
              }
              if (response.data.includes('Can view permission')){
                setCheckPermission(true);
              }
              if (response.data.includes('Can add group')){
                setAddRole(true);
              }
          }
      }).catch(error => {
          console.log(error);
      });
      }
        if (location.pathname.startsWith('/boards/')) {
          let checkboardsslug = window.location.pathname.split('/', 3);
          api.get(`${apiUrl}api/v1/boards/${checkboardsslug[2]}/`, {
              headers: {
                  'Authorization': 'Bearer ' + token
              },
          }).then((response) => {
              if (response.status === 200) {
                setBoardName(response.data.title);
                setauthor(response.data.author);
                localStorage.setItem('titleboards', response.data.title);
                localStorage.setItem('slugboards', response.data.slug);
                localStorage.setItem('boardID', response.data.id);
          }});
        }
          api.get(`${apiUrl}api/identity/auth/users/me/`, {
              headers: {
                  'Authorization': 'Bearer ' + token
              },
          }).then((response) => {
            if (response.status === 200) {
              setUser(response.data);
              if (response.data.username === author) {
                localStorage.setItem('author', author);
              }
              localStorage.setItem('username', response.data.username);
            }
          }).catch(error =>{
            console.error(error);
          });
        if (location.pathname.startsWith('/tasks/')) {
          api.get(`${apiUrl}api/v1/tasks/`, {
              headers: {
                  'Authorization': 'Bearer ' + token
              },
          }).then((response) => {
            if (response.status === 200) {
              const task = response.data;
              setTasksnumber(task.length);
            }
          }).catch(error =>{
              if (error.response.status === 403){
                 window.location.href = '/main';
            }
      });
    }
    CheckPermission();
    // eslint-disable-next-line
  }, []);

  if (location.pathname==='/login' || location.pathname==='/' 
  || location.pathname==='' || location.pathname==='/create-appeal' 
  || location.pathname==='/about' || location.pathname==='/privacy-policy'
  || location.pathname==='/developers-info/' || location.pathname==='/developers-info'
  || location.pathname==='/create-appeal/' 
  || location.pathname==='/about/' || location.pathname==='/privacy-policy/'
  || location.pathname==='/prices-info' || location.pathname==='/prices-info/'
  || location.pathname==='/solutions-info' || location.pathname==='/solutions-info/'
  || location.pathname==='/registration' || location.pathname==='/registration/'
  || location.pathname.startsWith('/invite-organization/')) {
    return (
      <header className="header">
            <div id="companyName">
              <Link onClick={() => window.location.href= '/main'} id="project-name">
                <img id="logo-tz" src={apiUrlFrontend + 'logo-light.svg'} alt=''/>
              </Link>
            </div>
            {CheckAuth()}
            <button style={{marginLeft: 'auto'}} id="open-modal-phone" onClick={()=>setModalIsOpenPhone(true)}>
              <img id="btn-modal-open" src={apiUrlFrontend + '/burger-btn.svg'} alt="" />
            </button>
            <Modal
              className="ModalPhone"
              isOpen={modalIsOpenPhone}
              onRequestClose={closeModal}
              overlayClassName="Overlay"
            >
                <div id="div-modal-phone">
                  <div id="div-for-close-and-buttons-modal">
                    <div id="div-all-btn-modal">
                      { location.pathname==='/developers-info' || location.pathname==='/developers-info/' ? 
                      <Link style={{color: "white", fontWeight: "800", marginRight: "5px"}} onClick={() => window.location.href= '/developers-info'} id="developers">Разработчики</Link>: 
                      <Link onClick={() => window.location.href= '/developers-info'} id="developers">Разработчики</Link>
                      }
                      { location.pathname==='/solutions-info' || location.pathname==='/solutions-info/' ?
                      <Link style={{color: "white", fontWeight: "800", marginRight: "5px"}} onClick={() => window.location.href= '/solutions-info'} id="solutions">Решения</Link>: 
                      <Link onClick={() => window.location.href= '/solutions-info'} id="solutions">Решения</Link>
                      }
                      { location.pathname==='/prices-info' || location.pathname==='/prices-info/' ?
                      <Link style={{color: "white", fontWeight: "800", marginRight: "5px"}} onClick={() => window.location.href= '/prices-info'} id="prices">Цены</Link>: 
                      <Link onClick={() =>  window.location.href= '/prices-info'} id="prices">Цены</Link>
                      }
                    </div>
                    <button id="close-modal-checklist" onClick={closeModal}>
                      <img id="btn-modal-close" src={apiUrlFrontend + '/close-btn.svg'} alt=""></img>
                    </button>
                  </div>
                </div>
              </Modal>
      </header>
    );
  } 
  else if (location.pathname==='/main' ||
     location.pathname.startsWith('/profile/') || 
     location.pathname==='/create-task' || 
     location.pathname==='/settings'
     || location.pathname==='/main-info' || location.pathname==='/main-info/') {
    return(
    <header className="header">
          <div id="companyName">
            <Link onClick={() => window.location.href= '/main'} id="project-name">
              <img id="logo-tz" src={apiUrlFrontend + 'logo-light.svg'} alt=''/>
          </Link>
          </div>
          <div className="divbtn">
            <button onMouseDown={(e) => handleGoToBoardsNewPin(e)} onClick={handleGoToBoards} id="openActiveBoards">Активные доски
            </button>
              <button onMouseDown={(e) => handleGoToTaskNewPin(e)} onClick={handleGoToTask} id="openActiveTasks">Задачи</button>
              {checkRole === true || (checkPermission === true && addRole === true) ?
                    <button
                        onMouseDown={(e) => handleGoToSettingsNewPin(e)}
                        onClick={(event) => handleGoToSettings(event)} 
                        id="openActiveBoards"
                    >
                        Настройки</button>
                : null
              }
              <button id="go-to-main-info-button" onMouseDown={(e) => handleGoToTechnicalSupportNewPin(e)}
               onClick={(event) => handleGoToTechnicalSupport(event)}>Обзор задач</button>
            {CheckAuth()}
          </div>
    </header>
    );
  }
  else if(location.pathname.startsWith('/boards/')) {
    return(
      <header className="header">
          <div id="companyName">
            <Link onClick={() => window.location.href= '/main'} id="project-name">
             <img id="logo-tz" src={apiUrlFrontend + 'logo-light.svg'} alt=''/>
            </Link>
              <label id="boardname">{boardname}</label>
            </div>
            <div className="divbtn">
              <button onClick={handleGoToBoards} onMouseDown={(e) => handleGoToBoardsNewPin(e)} id="openActiveBoards">Активные доски
              </button>
              <button onMouseDown={(e) => handleGoToTaskNewPin(e)} onClick={handleGoToTask} id="openActiveTasks">Задачи
              </button>
              {checkRole === true || (checkPermission === true && addRole === true) ?
                    <button 
                        onMouseDown={(e) => handleGoToSettingsNewPin(e)}
                        onClick={(event) => handleGoToSettings(event)} 
                        id="openActiveBoards"
                    >
                        Настройки</button>
                : null
              }
              <button id="go-to-main-info-button" onMouseDown={(e) => handleGoToTechnicalSupportNewPin(e)}
              onClick={(event) => handleGoToTechnicalSupport(event)}>Обзор задач</button>
              {CheckAuth()}
            </div>
      </header>
      );
  }
  else if(location.pathname === '/tasks/list/') {
    return(
      <header className="header">
          <div id="companyName">
            <Link onClick={() => window.location.href= '/main'} id="project-name">
              <img id="logo-tz" src={apiUrlFrontend + 'logo-light.svg'} alt=''/>
            </Link>
              <label id="boardname">{numberoftasks}</label>
          </div>
          <div className="divbtn">
            <button onClick={handleGoToBoards} onMouseDown={(e) => handleGoToBoardsNewPin(e)} id="openActiveBoards">Активные доски
            </button>
            <button onMouseDown={(e) => handleGoToTaskNewPin(e)} onClick={handleGoToTask} id="openActiveTasks">Задачи
            </button>
            {checkRole === true || (checkPermission === true && addRole === true) ?
                    <button 
                        onMouseDown={(e) => handleGoToSettingsNewPin(e)}
                        onClick={(event) => handleGoToSettings(event)} 
                        id="openActiveBoards"
                    >
                        Настройки</button>
                : null
            }
            <button id="go-to-main-info-button" onMouseDown={(e) => handleGoToTechnicalSupportNewPin(e)} onClick={(event) => handleGoToTechnicalSupport(event)}>Обзор задач</button>
            {CheckAuth()}
          </div>
      </header>
    );
  }
  else if(location.pathname.startsWith('/task/')) {
    return(
      <header className="header">
            <div id="companyName">
            <Link onClick={() => window.location.href= '/main'} id="project-name">
              <img id="logo-tz" src={apiUrlFrontend + 'logo-light.svg'} alt=''/>
            </Link>
              {getnumbertask()}
            </div>
            <div className="divbtn">
            <button onClick={handleGoToBoards} onMouseDown={(e) => handleGoToBoardsNewPin(e)} id="openActiveBoards">Активные доски
            </button>
              <button onMouseDown={(e) => handleGoToTaskNewPin(e)} onClick={handleGoToTask} id="openActiveTasks">Задачи
              </button>
              {checkRole === true || (checkPermission === true && addRole === true) ?
                    <button 
                        onMouseDown={(e) => handleGoToSettingsNewPin(e)}
                        onClick={(event) => handleGoToSettings(event)} 
                        id="openActiveBoards"
                    >
                        Настройки</button>
                : null
              }
              <button id="go-to-main-info-button" onMouseDown={(e) => handleGoToTechnicalSupportNewPin(e)} onClick={(event) => handleGoToTechnicalSupport(event)}>Обзор задач</button>
              <button onClick={handleGoToRevertBoard} onMouseDown={(e) => handleGoToRevertBoardNewPin(e)} id="openActiveTasks">Вернуться на доску
              </button>
              {CheckAuth()}
            </div>
      </header>
    );
  }
  else if (location.pathname.startsWith('/change-password/')) {
    return(
    <header className="header">
    <div id="companyName">
      <Link onClick={() => window.location.href= '/main'} id="project-name">
        <img id="logo-tz" src={apiUrlFrontend + 'logo-light.svg'} alt=''/>
      </Link>
    </div>
    <div id="div-with-circle">
      { location.pathname==='/developers-info' || location.pathname==='/developers-info/' ? 
        <Link style={{fontWeight: "800", marginRight: "5px"}} onClick={() => window.location.href= '/developers-info'} id="developers">Разработчики</Link>: 
        <Link onClick={() => window.location.href= '/developers-info'} id="developers">Разработчики</Link>
      }
      <div className="circle"></div>
      { location.pathname==='/solutions-info' || location.pathname==='/solutions-info/' ?
        <Link style={{fontWeight: "800", marginRight: "5px"}} onClick={() => window.location.href= '/solutions-info'} id="solutions">Решения</Link>: 
        <Link onClick={() => window.location.href= '/solutions-info'} id="solutions">Решения</Link>
      } 
      <div className="circle"></div>
      { location.pathname==='/prices-info' || location.pathname==='/prices-info/' ?
        <Link style={{fontWeight: "800", marginRight: "5px"}} onClick={() => window.location.href= '/prices-info'} id="prices">Цены</Link>: 
        <Link onClick={() => window.location.href= '/prices-info'} id="prices">Цены</Link>
      }
    </div>
</header>
    )
  }
  else if (location.pathname.startsWith('/choice-organization')){
    return (
      <header className="header">
      </header>
    )
  }
  else {
    return(
      <h1>Такой страницы не существует</h1>
    );
  }
}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     
export default Header;