import React, { useState, useEffect, useCallback, useRef } from 'react';
import './styles/DetailTask.css';
import Modal from 'react-modal';
import Calendar from 'react-calendar';
import { useDropzone } from 'react-dropzone';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import UserFile from './components/UserFile';
import {
	ClassicEditor,
	AccessibilityHelp,
	Alignment,
	Autoformat,
	AutoImage,
	AutoLink,
	Autosave,
	BlockQuote,
	Bold,
	Code,
	CodeBlock,
	Essentials,
	FindAndReplace,
	FontBackgroundColor,
	FontColor,
	FontFamily,
	FontSize,
	FullPage,
	GeneralHtmlSupport,
	Heading,
	Highlight,
	HorizontalLine,
	HtmlEmbed,
	ImageBlock,
	ImageInline,
    // eslint-disable-next-line
	ImageInsert,
	ImageInsertViaUrl,
	ImageResize,
	ImageStyle,
	ImageToolbar,
    // eslint-disable-next-line
	ImageUpload,
	Indent,
	IndentBlock,
	Italic,
	Link,
	LinkImage,
	List,
	ListProperties,
	Markdown,
	MediaEmbed,
	Paragraph,
	PasteFromOffice,
	RemoveFormat,
	SelectAll,
	ShowBlocks,
	SimpleUploadAdapter,
	SourceEditing,
	SpecialCharacters,
	SpecialCharactersArrows,
	SpecialCharactersCurrency,
	SpecialCharactersEssentials,
	SpecialCharactersLatin,
	SpecialCharactersMathematical,
	SpecialCharactersText,
	Strikethrough,
	Style,
	Subscript,
	Superscript,
	Table,
	TableCaption,
	TableCellProperties,
	TableColumnResize,
	TableProperties,
	TableToolbar,
	TextTransformation,
	TodoList,
	Underline,
	Undo
} from 'ckeditor5';
// import './styles/cked.css';
// import 'ckeditor5/ckeditor5.css';
import translations from 'ckeditor5/translations/ru.js';
import api from '/app/frontend/src/api';
import { format } from 'date-fns';
import { Tooltip } from 'react-tooltip';

const DetailTask = () => {
    Modal.setAppElement('#root');
    const apiUrlFrontend = process.env.REACT_APP_FRONTEND_URL;
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');
    const username = localStorage.getItem('username');
    const [taskhistory, setTaskHistory] = useState([]);
    const [task, setTask] = useState([]);
    const [selectedtask, setselectedtask] = useState([]);
    const [commentuser, setCommentUser] = useState('');
    const [comments, SetComments] = useState([]);
    const [rating, setRating] = useState(null);
    const [hover, setHover] = useState(null);
    const [totalStars] = useState(10);
    const [isTextSelected, setIsTextSelected] = useState(false);
    const [descriptiontask, setDescriptionTask] = useState('');
    const [titletask, setTitleTask] = useState('');
    const [selectedhistorys, setSelectedHistorys] = useState([]);
    const [selectedComments, setSelectedComments] = useState([]);
    const [history] = useState([]);
    const [comment] = useState([]);
    const [isActiveComment, setIsActiveComment] = useState(false);
    const [isActiveHistory, setIsActiveHistory] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalIsOpenResponsible, setIsOpenResponsible] = useState(false);
    const [modalIsOpenLabel, setIsOpenLabel] = useState(false);
    const [modalIsOpenGetLabel, setIsOpenGetLabel] = useState(false);
    const [modalIsOpenCalendar, setIsOpenCalendar] = useState(false);
    const [modalIsOpenUniteSubtasks, setModalIsOpenUniteSubtasks] = useState(false);
    const [performer, setperformer] = useState([]);
    const [label, setLabel] = useState([]);
    const [selectedlabels, setSelectedLabels] = useState([]);
    const [selectedperformers, setSelectedPerformers] = useState([]);
    const [selectedResponsibles, setSelectedResponsibles] = useState([]);
    const [selectedColor, setSelectedColor] = useState('');
    const [namelabel, setNameLabel] = useState('');
    const [group, setGroup] = useState([]);
    const [groups, setGroups] = useState([]);
    const [boardid, setboardid] = useState("");
    const [handledeletelabel, sethandledeletelabel] = useState(false);
    const [relatedSubtasks, setRelatedSubtasks] = useState([]);
    const [isHovered, setIsHovered] = useState(false);
    const timerRef = useRef(null);
    const webcolors = [
        "#FF0000", "#FF7F50", "#FFD700", "#ADFF2F",
        "#32CD32", "#20B2AA", "#00CED1", "#4682B4",
        "#1E90FF", "#6A5ACD", "#9370DB", "#FF1493",
        "#A9A9A9", "#FFFFFF", "#000000", "#808080",
    ];
    const [value, onChangeCalendar] = useState(new Date());
    const [modalIsOpencomment, setmodalIsOpencomment] = useState(false);
    const [changecomment, setChangeComment] = useState([]);
    const [selectedcomment, setSelectedComment] = useState('');
    const [files, setFiles] = useState([]);
    const [openModalChangeGroup, setopenModalChangeGroup] = useState(false);
    const [isactiveupdatetasks, setisactiveupdatetasks] = useState(false);
    const [tasklist, settasklist] = useState([]);
    const [filtertasklist, setfiltertasklist] = useState([]);
    const [selectedSubtasks, setselectedSubtasks] = useState([]);
    const [searchitem, setsearchitem] = useState([]);
    const [modalIsOpenAnswerComment, setmodalIsOpenAnswerComment] = useState(false);
    const [selectedcommentanswer, setselectedcommentanswer] = useState([]);
    const [answercommenttext, setanswercommenttext] = useState("");
    const [hintselectuserselect, sethintselectuserselect] = useState(false);
    const [hintuserfilter, sethintuserfilter] = useState([]);
    // const [allObjectFiles, setAllObjectFiles] = useState([]);
    const [canChangeTask, setCanChangeTask] = useState(false);
    const [canViewComment, setViewComment] = useState(false);
    const [canViewHistoryTask, setCanViewHistoryTask] =  useState(false);
    const [canAddComment, setCanAddComment] = useState(false);
    const [canChangeComment, setCanChangeComment] = useState(false);
    const [canDeleteComment, setCanDeleteComment] = useState(false);
    const [canDeleteTask, setCanDeleteTask] = useState(false);
    const [canViewLabel, setCanViewLabel] = useState(false);
    const [canViewUser, setCanViewUser] = useState(false);
    const [openviewfile, setopenviewfile] = useState(false);
    const [openModalChangeBoard, setopenModalChangeBoard] = useState(false);
    const [board, setBoard] = useState([]);
    const page = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [openModalChangeGroupTask, setopenModalChangeGroupTask] = useState(false);
    const [changegroup, setchangegroup] = useState([]);
    // eslint-disable-next-line
    const [openModalCreateRelatedTask, setopenModalCreateRelatedTask] = useState(false);
    const [activeSeeSubtasks, setActiveSeeSubtasks] = useState(false);
    const editorConfig = {
        toolbar: {
			items: [
				'undo',
				'redo',
				'|',
				'sourceEditing',
				'showBlocks',
				'findAndReplace',
				'selectAll',
				'|',
				'heading',
				'style',
				'|',
				'fontSize',
				'fontFamily',
				'fontColor',
				'fontBackgroundColor',
				'|',
				'bold',
				'italic',
				'underline',
				'strikethrough',
				'subscript',
				'superscript',
				'code',
				'removeFormat',
				'|',
				'specialCharacters',
				'horizontalLine',
				'link',
				'insertImage',
				'mediaEmbed',
				'insertTable',
				'highlight',
				'blockQuote',
				'codeBlock',
				'htmlEmbed',
				'|',
				'alignment',
				'|',
				'bulletedList',
				'numberedList',
				'todoList',
				'outdent',
				'indent',
				'|',
				'accessibilityHelp'
			],
			shouldNotGroupWhenFull: true
		},
		plugins: [
			AccessibilityHelp,
			Alignment,
			Autoformat,
			AutoImage,
			AutoLink,
			Autosave,
			BlockQuote,
			Bold,
			Code,
			CodeBlock,
			Essentials,
			FindAndReplace,
			FontBackgroundColor,
			FontColor,
			FontFamily,
			FontSize,
			FullPage,
			GeneralHtmlSupport,
			Heading,
			Highlight,
			HorizontalLine,
			HtmlEmbed,
			ImageBlock,
			ImageInline,
			// ImageInsert,
			ImageInsertViaUrl,
			ImageResize,
			ImageStyle,
			ImageToolbar,
			// ImageUpload,
			Indent,
			IndentBlock,
			Italic,
			Link,
			LinkImage,
			List,
			ListProperties,
			Markdown,
			MediaEmbed,
			Paragraph,
			PasteFromOffice,
			RemoveFormat,
			SelectAll,
			ShowBlocks,
			SimpleUploadAdapter,
			SourceEditing,
			SpecialCharacters,
			SpecialCharactersArrows,
			SpecialCharactersCurrency,
			SpecialCharactersEssentials,
			SpecialCharactersLatin,
			SpecialCharactersMathematical,
			SpecialCharactersText,
			Strikethrough,
			Style,
			Subscript,
			Superscript,
			Table,
			TableCaption,
			TableCellProperties,
			TableColumnResize,
			TableProperties,
			TableToolbar,
			TextTransformation,
			TodoList,
			Underline,
			Undo
		],
		fontFamily: {
			supportAllValues: true
		},
		fontSize: {
			options: [10, 12, 14, 'default', 18, 20, 22],
			supportAllValues: true
		},
		heading: {
			options: [
				{
					model: 'paragraph',
					title: 'Paragraph',
					class: 'ck-heading_paragraph'
				},
				{
					model: 'heading1',
					view: 'h1',
					title: 'Heading 1',
					class: 'ck-heading_heading1'
				},
				{
					model: 'heading2',
					view: 'h2',
					title: 'Heading 2',
					class: 'ck-heading_heading2'
				},
				{
					model: 'heading3',
					view: 'h3',
					title: 'Heading 3',
					class: 'ck-heading_heading3'
				},
				{
					model: 'heading4',
					view: 'h4',
					title: 'Heading 4',
					class: 'ck-heading_heading4'
				},
				{
					model: 'heading5',
					view: 'h5',
					title: 'Heading 5',
					class: 'ck-heading_heading5'
				},
				{
					model: 'heading6',
					view: 'h6',
					title: 'Heading 6',
					class: 'ck-heading_heading6'
				}
			]
		},
		htmlSupport: {
			allow: [
				{
					name: /^.*$/,
					styles: true,
					attributes: true,
					classes: true
				}
			]
		},
		image: {
			toolbar: ['imageTextAlternative', '|', 'imageStyle:inline', 'imageStyle:wrapText', 'imageStyle:breakText', '|', 'resizeImage']
		},
		language: 'ru',
		link: {
			addTargetToExternalLinks: true,
			defaultProtocol: 'https://',
			decorators: {
				toggleDownloadable: {
					mode: 'manual',
					label: 'Downloadable',
					attributes: {
						download: 'file'
					}
				}
			}
		},
		list: {
			properties: {
				styles: true,
				startIndex: true,
				reversed: true
			}
		},
		placeholder: 'Type or paste your content here!',
		style: {
			definitions: [
				{
					name: 'Article category',
					element: 'h3',
					classes: ['category']
				},
				{
					name: 'Title',
					element: 'h2',
					classes: ['document-title']
				},
				{
					name: 'Subtitle',
					element: 'h3',
					classes: ['document-subtitle']
				},
				{
					name: 'Info box',
					element: 'p',
					classes: ['info-box']
				},
				{
					name: 'Side quote',
					element: 'blockquote',
					classes: ['side-quote']
				},
				{
					name: 'Marker',
					element: 'span',
					classes: ['marker']
				},
				{
					name: 'Spoiler',
					element: 'span',
					classes: ['spoiler']
				},
				{
					name: 'Code (dark)',
					element: 'pre',
					classes: ['fancy-code', 'fancy-code-dark']
				},
				{
					name: 'Code (bright)',
					element: 'pre',
					classes: ['fancy-code', 'fancy-code-bright']
				}
			]
		},
		table: {
			contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
		},
		translations: [translations],
        // eslint-disable-next-line
        placeholder: "Введите описание задачи"
    };

    const handleOpenGroup = () => {
        closeModal();
        setopenModalChangeGroup(true);
        api.get(`${apiUrl}api/v1/groups/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => { 
            if (response.status === 200) {
                setGroups(response.data);
            }
        }).catch(error => {
            const status = error.response ? error.response.status: null;
            if (error.response.status === 403)
            {
                alert(`Код ошибки: ${status}, ${error.response.data.detail}`, error);
            }
        }); 
    };

    const handleOpenModalCreateRelatedTask = (event) => {
        localStorage.setItem('grouptaskcreate', task.group_name);
        localStorage.setItem('grouptasksystemstatus', task.system_status_id);
        localStorage.setItem('grouptaskidcreate', task.group);
        localStorage.setItem('relatedtaskid', task.task_id);
        window.location.href = '/create-task';
    };

    const handleOpenChangeGroup = (event, board) => {
        closeModal();
        const performerthistask = performer.filter(pf => task.performer.includes(pf.username)).map(pf => pf.id);
        if (performerthistask.some(tpf => !board.users.includes(tpf)))
        {
            alert("У исполнителей нет доступа к доске, в которую вы хотите переместить задачу.");
        }
        else {
            api.get(`${apiUrl}api/v1/groups/`, {
                params: {
                    'board_id': board.slug
                },
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                }).then((response) => {
                    if (response.status === 200) {
                        setchangegroup(response.data);
                        setopenModalChangeGroupTask(true);
                    }
                }).catch(error => {
                    if (error.response.status !== 403) {
                    }
            });
        }
    };

    const handlechangeboardgrouptask = (event, group) => {
        if (selectedtask.group === group.group_id) {
            alert("Не тыкай пыыросто так");
        }
        else {
            api.patch(`${apiUrl}api/v1/tasks/${task.task_id}/update-group/`, {
                'group': group.group_id
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    closeModal();
                    updateall();
                }
            }).catch(error =>{
                console.error(error);
            });
        }
    };

    function updateTaskGroup(group_id) {
        const split = (window.location.pathname).split('/');
        const task_pk = split[2];
        if (selectedtask.group === group_id) {
            alert("Не тыкай просто так");
        }
        else{
            api.patch(`${apiUrl}api/v1/tasks/${task_pk}/update-group/`, {
                'group': group_id
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    updateall();
                    closeModal();
                }
            }).catch(error =>{
                console.error(error);
            });
        }
    };

    const onDropRejected = (rejectedFiles) => {
        rejectedFiles.forEach(file => {
            if (file.errors[0].code === 'file-too-large') {
                alert('Ошибка: Файл должен быть не более 25MB');
            } else {
                alert('Произошла ошибка при загрузке файла: ' + file.errors[0].message);
            }
        });
    };

    const onDrop = useCallback((acceptedFiles) => {
        setFiles(acceptedFiles.map((file) => file));
        handleUpload(acceptedFiles);
        // eslint-disable-next-line
    }, []);

    const {getRootProps, getInputProps} = useDropzone({onDrop, onDropRejected, maxSize: 26214400, })
    
    function handleUpload(acceptedFiles) {
        if (files){
            const split = (window.location.pathname).split('/');
            const task_pk = split[2];
            const formData = new FormData();
            acceptedFiles.forEach((file) => {
                formData.append('file', file);
            });
            api.post(`${apiUrl}api/upload-file/`, formData, {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            })
            .then(response => {
                if (response.status === 201) {
                    let existingfiles = localStorage.getItem('selected_files');
                    if (existingfiles === "")
                    {
                        AddFileToTask({task_id: task_pk, 
                            file_id: response.data.map(file => file.id)}
                        );
                    }
                    else{
                        let existing = existingfiles.split(',').map(item => parseInt(item));
                        let allfiles = [...existing, ...response.data.map(file => file.id)];
                        AddFileToTask({task_id: task_pk, 
                            file_id: allfiles}
                        );
                    } 
                }
            })
            .catch(error => {
                console.error(error);
            });
        }
    };

    function AddFileToTask (data){
        api.patch(`${apiUrl}api/v1/tasks/${data.task_id}/`, {
            'file': data.file_id,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }
        ).then((response) => {
        if (response.status === 200) {
            setFiles([]);
            updateall();
        }
        }).catch(error => {
            console.error(error);         
        });
    };

    const handleUpdateTaskDeadline = (date) => {
        const formattedDate = format(new Date(date), 'yyyy-MM-dd');
        const split = (window.location.pathname).split('/');
        const task_pk = split[2];
        if (selectedtask.deadline === formattedDate) {
            alert("Не тыкай просто так");
        }
        else {
            api.patch(`${apiUrl}api/v1/tasks/` + task_pk + '/', {
                'deadline': formattedDate,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
            if (response.status === 200) { 
                // alert("update success epta")
                updateall();
            if (isActiveHistory === true){
                updateHistory();
            }
            closeModal();
            }}).catch(error => {
                console.error(error);
            });
        }
    };

    function closeModal() {
        setIsOpen(false);
        setIsOpenLabel(false);
        setIsOpenGetLabel(false);
        setIsOpenCalendar(false);
        setChangeComment([]);
        setopenModalChangeBoard(false);
        setmodalIsOpencomment (false);
        setopenModalChangeGroup(false);
        setModalIsOpenUniteSubtasks(false);
        setmodalIsOpenAnswerComment(false);
        setopenModalChangeGroupTask(false);
        setIsOpenResponsible(false);
    };

    function openModalCalendar() {
        closeModal();
        setIsOpenCalendar(true);
    };

    function openModal() {
        closeModal();
        setIsOpen(true);
    };

    function openModalResponsible() {
        closeModal();
        setIsOpenResponsible(true);
    };

    function openModalLabel() {
        closeModal();
        setIsOpenLabel(true);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSendCommentClick(e);
        }
    };

    function openModalGetLabel() {
        closeModal();
        api.get(`${apiUrl}api/v1/labels/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                setLabel(response.data);
                setIsOpenGetLabel(true);
            }
        }).catch(error =>{
            console.error(error);
            const status = error.response ? error.response.status: null;
            if (error.response.status === 403) {
                alert(`Код ошибки: ${status}, ${error.response.data.detail}`, error);
            }
            // alert(`Ошибка: ${error.code}, Код ошибки: ${status}`, error)
        });
    };

    const handleUpdateTitleDescTask = () => {
        setisactiveupdatetasks(!isactiveupdatetasks);
    };

    const handleCreateLabel = (event) => {
        event.preventDefault();
        if (label.some(labeli => labeli.name === namelabel)) {
            alert("Метка с таким названием уже существует");
        }
        else {
            api.post(`${apiUrl}api/v1/labels/`, {
                'name': namelabel,
                'color': selectedColor
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 201) {
                    setSelectedColor("");
                    setNameLabel("");
                    updateall();
                    closeModal();
                }
            }).catch(error => {
                console.error(error);
                const status = error.response ? error.response.status: null;
                if (error.response.status === 400) {
                    if (error.response.data.name) {
                        alert(`Код ошибки: ${status}, ${error.response.data.name}`, error);
                    }
                    else {
                        alert(`Код ошибки: ${status}, ${error.response.data.color}`, error);
                    }
                }
            });
        }
    };

    const handleChangeNameLabel = (event) => {
        setNameLabel(event.target.value);
    }

    const handleCheckboxChangePerformer = (username) => {
        if (selectedperformers.includes(username)) {
            setSelectedPerformers(selectedperformers.filter((selectedPerformer) => selectedPerformer !== username));
            const filterforperformer = performer.filter((performer) => (selectedperformers.filter((selectedPerformer) => selectedPerformer !== username).includes(performer.username)));
            const performerid = filterforperformer.map(performer => performer.id);
            const split = (window.location.pathname).split('/');
            const task_pk = split[2];
            api.patch(`${apiUrl}api/v1/tasks/` + task_pk + '/', {
                'performer': performerid,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                    if (response.status === 200) {
                    updateall();
                    if (isActiveHistory === true){
                        updateHistory();
                    }
                }
            }).catch(error => {
                console.error(error);
            });
        } else {
            setSelectedPerformers([...selectedperformers, username]);
            const filterforperformer = performer.filter((performer) => [...selectedperformers, username].includes(performer.username));
            const performerid = filterforperformer.map(performer => performer.id);
            const split = (window.location.pathname).split('/');
            const task_pk = split[2];
            api.patch(`${apiUrl}api/v1/tasks/` + task_pk + '/', {
                'performer': performerid,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    updateall();
                    if (isActiveHistory === true){
                        updateHistory();
                    }
                }
            }).catch(error => {
                console.error(error);
            });
        }
    };

    const handleCheckboxChangeResponsible = (username) => {
        if (selectedResponsibles.includes(username)) {
            setSelectedResponsibles(selectedResponsibles.filter((selectedPerformer) => selectedPerformer !== username));
            const filterforperformer = performer.filter((performer) => (selectedResponsibles.filter((selectedPerformer) => selectedPerformer !== username).includes(performer.username)));
            const performerid = filterforperformer.map(performer => performer.id);
            const split = (window.location.pathname).split('/');
            const task_pk = split[2];
            api.patch(`${apiUrl}api/v1/tasks/` + task_pk + '/', {
                'responsible': performerid,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                    if (response.status === 200) {
                    updateall();
                    if (isActiveHistory === true){
                        updateHistory();
                    }
                }
            }).catch(error => {
                console.error(error);
            });
        } else {
            setSelectedResponsibles([...selectedResponsibles, username]);
            const filterforperformer = performer.filter((performer) => [...selectedResponsibles, username].includes(performer.username));
            const performerid = filterforperformer.map(performer => performer.id);
            const split = (window.location.pathname).split('/');
            const task_pk = split[2];
            api.patch(`${apiUrl}api/v1/tasks/` + task_pk + '/', {
                'responsible': performerid,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    updateall();
                    if (isActiveHistory === true){
                        updateHistory();
                    }
                }
            }).catch(error => {
                console.error(error);
            });
        }
    };


    const handleCheckboxChangeLabel = (label) => {
        if (selectedlabels.includes(label)) {
            setSelectedLabels(selectedlabels.filter((selectedLabel) => selectedLabel !== label));
            const split = (window.location.pathname).split('/');
            const task_pk = split[2];
            api.patch(`${apiUrl}api/v1/tasks/` + task_pk + '/', {
                'label': selectedlabels.filter((selectedLabel) => selectedLabel !== label),
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) { 
                    updateall();
                    if (isActiveHistory === true){
                        updateHistory();
                    }
                    // closeModal()
                }
            }).catch(error => {
                console.error(error);
            });
        } else {
            setSelectedLabels([...selectedlabels, label]);
            const split = (window.location.pathname).split('/');
            const task_pk = split[2];
            api.patch(`${apiUrl}api/v1/tasks/` + task_pk + '/', {
                'label': [...selectedlabels, label],
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) { 
                    updateall();
                    if (isActiveHistory === true){
                        updateHistory();
                    }
                    // closeModal()
                }
            }).catch(error => {
                console.error(error);
            });
        }
    };

    const handleCheckboxChangeHistorys = (history) => {
        if (selectedhistorys.includes(history)) {
            setSelectedHistorys(selectedhistorys.filter((selectedHistory) => selectedHistory !== history));
            setIsActiveHistory(false);
            getnumbertask();
        } else {
            setSelectedHistorys([...selectedhistorys, history]);
            setIsActiveHistory(true);
            updateHistory();
            getnumbertask();
        }
    };

    const handleCheckboxChangeComments = (comment) => {
        if (selectedComments.includes(comment)) {
            setSelectedComments(selectedComments.filter((selectedComment) => selectedComment !== comment));
            setIsActiveComment(false);
            getnumbertask();
        } else {
            setSelectedComments([...selectedComments, comment]);
            setIsActiveComment(true);
            updateComment();
            getnumbertask();
        }
    };

    const handleOpenProfileClick = (user) => {
        window.location.href = "/profile/" + user;
    };

    function updateComment() {
        const split = (window.location.pathname).split('/');
        const task_pk = split[2];
        api.get(`${apiUrl}api/v1/tasks/${task_pk}/comments/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => { 
            if (response.status === 200) {
                SetComments(response.data);
            }
        }).catch(error => {
            console.error(error);
            const status = error.response ? error.response.status: null;
            if (error.response.status === 403)
            {
                alert(`Код ошибки: ${status}, ${error.response.data.detail}`, error);
            }
                // alert(`Ошибка: ${error.code}, Код ошибки: ${status}`, error)
        });
    };

    function updateHistory() {
        const split = (window.location.pathname).split('/');
        const task_pk = split[2];
        api.get(`${apiUrl}api/v1/tasks/${task_pk}/historys/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            if (response.status === 200){
                setTaskHistory(response.data);
            }
        }).catch(error => {
            console.error(error);
            const status = error.response ? error.response.status: null;
            if (error.response.status === 403)
            {
                alert(`Код ошибки: ${status}, ${error.response.data.detail}`, error);
            }
            // alert(`Ошибка: ${error.code}, Код ошибки: ${status}`, error)
        });
    };

    function updateall() {
        const loadBoards = async () => {
            await api.get(`${apiUrl}api/v1/user/permissions/`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 200) {
                    for (const item in response.data) {
                        if (response.data.includes('owner_organization')) {
                            setCanChangeTask(true);
                            setViewComment(true);
                            setCanViewHistoryTask(true);
                            setCanAddComment(true);
                            setCanChangeComment(true);
                            setCanDeleteTask(true);
                            setCanDeleteComment(true);
                            setCanViewLabel(true);
                            setCanViewUser(true);
                        }
                        if (response.data[item] === 'Can change Задача') {
                            setCanChangeTask(true);
                        }
                        else if (response.data[item] === 'Can view Комментарий') {
                            setViewComment(true);   
                        }
                        else if (response.data[item] === 'Can view historical Задача') {
                            setCanViewHistoryTask(true);
                        }
                        else if (response.data[item] === 'Can add Комментарий') {
                            setCanAddComment(true);
                        }
                        else if (response.data[item] === 'Can change Комментарий') {
                            setCanChangeComment(true);
                        }
                        else if (response.data[item] === 'Can delete Задача') {
                            setCanDeleteTask(true);
                        }
                        else if (response.data[item] === 'Can delete Комментарий') {
                            setCanDeleteComment(true);
                        }
                        else if (response.data[item] === 'Can view Метка') {
                            setCanViewLabel(true);
                        }
                        else if (response.data[item] === 'Can view Пользователь') {
                            setCanViewUser(true);
                        }
                    }
                }
            }).catch(error => {
                console.log(error);
            });
        }
        loadBoards();
        const split = (window.location.pathname).split('/');
        const task_pk = split[2];
        api.get(`${apiUrl}api/v1/tasks/${task_pk}/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            if (response.status === 200){
                setRating(response.data.priority);
                setTitleTask(response.data.title);
                if (response.data.description) {
                    setDescriptionTask(response.data.description);
                }
                setSelectedPerformers(response.data.performer);
                setSelectedResponsibles(response.data.responsible);
                setSelectedLabels(response.data.label.map(lab => lab.id));
                setGroup(response.data.group_name);
                if (response.data.deadline) {
                    onChangeCalendar(new Date(response.data.deadline));
                }
                localStorage.setItem('groupid', response.data.group);
                GetGroups(response.data.group);
                localStorage.setItem('selected_files', response.data.file_id.map(file => file));
                setTask(response.data);
                setselectedtask(response.data);
                setselectedSubtasks(response.data.subtasks);
                if (response.data.subtasks && response.data.subtasks.length > 0) {
                    api.get(`${apiUrl}api/v1/tasks/${response.data.task_id}/get-related-task/`, {
                        headers: {
                            'Authorization': 'Bearer ' + token
                        }
                    }).then((response) => {
                        if (response.status === 200){
                            console.log(response.data)
                            setRelatedSubtasks(response.data);
                        }
                    }).catch(error => {
                        console.error(error);
                        const status = error.response ? error.response.status: null;
                        if (error.response.status === 403) {
                            alert(`Код ошибки: ${status}, ${error.response.data.detail}`, error);
                                window.location.href = '/main';
                            }
                    });
                    setActiveSeeSubtasks(true);
                }
                setopenviewfile(true);
            }
        }).catch(error => {
            console.error(error);
            const status = error.response ? error.response.status: null;
            if (error.response.status === 403) {
                alert(`Код ошибки: ${status}, ${error.response.data.detail}`, error);
                window.location.href = '/main';
            }
            // alert(`Ошибка: ${error.code}, Код ошибки: ${status}`, error)
        });
        fetchAllUsers();
        updateComment();
        handleCheckboxChangeComments(comment);
    };

    useEffect(() => {
        if (isActiveHistory === false) {
            setIsActiveComment(true);
        }
        else{
            setIsActiveComment(false);
        }
    }, [isActiveHistory]);
    
    useEffect(() => {
        updateall();
        // eslint-disable-next-line
    }, []);

    function fetchAllUsers(page = 1, allUsers = []) {
        api.get(`${apiUrl}api/identity/users/`, {
            params: { page: page },
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                const fetchedUsers = response.data;
                const updatedUsersList = allUsers.concat(fetchedUsers);
                if (response.data.next) {
                    fetchAllUsers(page + 1, updatedUsersList);
                } else {
                    setperformer(updatedUsersList);
                }
            }
        }).catch(error => {
            console.error(error);
        });
    };

    function GetGroups (groupid) {
        api.get(`${apiUrl}api/v1/groups/${groupid}/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                localStorage.setItem('groupid', '');
                setboardid(response.data.board_id);
            }
        }).catch(error =>{
            console.error(error);
            const status = error.response ? error.response.status: null;
            if (error.response.status === 403) {
                alert(`Код ошибки: ${status}, ${error.response.data.detail}`, error);
            }
            // alert(`Ошибка: ${error.code}, Код ошибки: ${status}`, error)
        });
    };

    const handleTitleTaskChange = (event) => {
        setTitleTask(event.target.value);
    };

    const handleCommentTaskChange = (event) => {
        const match = event.target.value.match(/(?:^|\s)@(\w*)$/);
        if (match) {
            sethintselectuserselect(true);
            const searchTerm = match[1].toLowerCase();
            sethintuserfilter(performer.filter(user => user.username.toLowerCase().startsWith(searchTerm)));
        }
        else {
            sethintselectuserselect(false);
        }
        setCommentUser(event.target.value);
    };

    const handleTextareaClick = (event) => {
        const textarea = event.target;
        if (!isTextSelected){
            textarea.focus();
            textarea.setSelectionRange(0, textarea.value.length);
            setIsTextSelected(true);
        }
        else{
            setIsTextSelected(false);
        }
    };

    const handleSendCommentClick = (event) => {
        event.preventDefault();
        const split = (window.location.pathname).split('/');
        const task_pk = split[2];
        api.post(`${apiUrl}api/v1/tasks/${task_pk}/comments/`, {
            'text': commentuser
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            if (response.status === 201){
                updateComment();
                setCommentUser("");
                sethintselectuserselect(false);
                if (isActiveComment === false && isActiveHistory === false) {
                    let input = document.getElementById('input-for-comment');
                    input.value = '';
                }
                else {
                    let input = document.getElementById('input-for-comment-is-active');
                    input.value = '';
                }
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleChangePriorityTask = (priority) => {
        setRating(priority);
        if (selectedtask.priority === priority) {

        }
        else{
            const split = (window.location.pathname).split('/');
            const task_pk = split[2];
            api.patch(`${apiUrl}api/v1/tasks/` + task_pk + '/', {
                'priority': priority,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token  
                },
            }).then((response) => {
            if (response.status === 200) { 
                if (isActiveHistory === true){
                    updateHistory();
                }
                updateall();
            }
            }).catch(error => {
                console.error(error);
            });
        }
    };

    const handleUpdateDescTask = () => {
        setIsTextSelected(false);
        if (selectedtask.title === titletask && selectedtask.description === descriptiontask) {
            alert("Не тыкай просто так");
        }
        else {
            const split = (window.location.pathname).split('/');
            const task_pk = split[2];
            api.patch(`${apiUrl}api/v1/tasks/` + task_pk + '/', {
                'title': titletask,
                'description': descriptiontask,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
            if (response.status === 200) { 
                // alert("update success epta");
                setisactiveupdatetasks(false);
                updateall();
                setisactiveupdatetasks(false);
            }
            }).catch(error => {
                console.error(error);
            });
        }
    };

    const handleChangeComment = (data) => {
        closeModal();
        setChangeComment([]);
        setChangeComment(data);
        setmodalIsOpencomment(true);
    };

    function handleSubmitChangeComment(event) {
        event.preventDefault();
        const split = (window.location.pathname).split('/');
        const task_pk = split[2];
        api.patch(`${apiUrl}api/v1/tasks/${task_pk}/comments/${changecomment.id}/`, {
            'text': selectedcomment,
        },{
            headers: {
                'Authorization': 'Bearer ' + token
           },
        }).then((response) => {
            if (response.status === 200) {
                closeModal();
                updateComment();
            }
        }).catch(error =>{
            console.error(error);
        });
    };

    const handledCommentChange = (event) => {
        setSelectedComment(event.target.value);
    };

    const handleClickGoToRelatedTaskNewPin = (s, e) => {
        if (e.button === 1) {
            e.preventDefault();
            window.open('/task/' + s);
        }
    }

    const handleGoToRelatedTask = (s, e) => {
        window.location.href = `/task/${s}`
    }

    function renderComment(comment){
        const isCurrentUser = comment.author === username;
        const commentclass = isCurrentUser ? "comment-right" : "comment-left";
        const imageclass = isCurrentUser ? "div-right" : "div-left";
        return(
            <div id="comment-image">
                {performer.filter(pf => pf.username === comment.author).map((k, kindex) => (
                    k.image ?
                    <div className={imageclass}>
                        <img className='image-comment-user'
                            src={k.image}   
                            alt=""
                        >
                        </img>
                        <label id="comment-username">
                            {k.username.slice(0,5)}
                        </label>
                    </div>
                        :
                    <div className={imageclass}>
                        <img id="not-image-comment-performer"
                            src={apiUrlFrontend + "person-square.svg"}
                            alt=""
                        >
                        </img>
                        <label id="comment-username">
                            {k.username.slice(0,5)}
                        </label>
                    </div>
                ))}
                <div className={commentclass}>
                    {commentclass === 'comment-right' 
                    ? canChangeComment ?
                    <button id="comment-text-button"
                        onClick={() => handleChangeComment(comment)}
                    >
                        {/* {format(new Date(comment.created_at), 'dd.MM.yyyy')} */}
                        {comment.text}
                    </button> : <button id="comment-text-button">
                        {comment.text}
                    </button>
                    : canAddComment ?
                    <button id="comment-text-button"
                        onClick={() => handleAnswerComment(comment)}
                    >
                        {comment.text}
                    </button>:
                    <button id="comment-text-button">
                        {comment.text}
                    </button> }
                    <label id='date-time-label-in-comment'>
                        {format(new Date(comment.created_at), 'dd.MM.yyyy')}
                    </label>
                    
                </div>
            </div>
        );
    };

    const handleOpenRelatedTask = (event, task_id) => {
        event.preventDefault();
        api.get(`${apiUrl}api/v1/tasks/${task_id}/get-related-task/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            if (response.status === 200){
                console.log(response.data)
                setRelatedSubtasks(response.data);
            }
        }).catch(error => {
            console.error(error);
            const status = error.response ? error.response.status: null;
            if (error.response.status === 403) {
                alert(`Код ошибки: ${status}, ${error.response.data.detail}`, error);
                    window.location.href = '/main';
                }
        });
        setActiveSeeSubtasks(!activeSeeSubtasks);
    }

    const handleAnswerComment = (comment) => {
        closeModal();
        setselectedcommentanswer(comment);
        setmodalIsOpenAnswerComment(true);
    };

    const handleDeleteComment = (event) => {
        event.preventDefault();
        const split = (window.location.pathname).split('/');
        const task_pk = split[2];
        api.delete(`${apiUrl}api/v1/tasks/${task_pk}/comments/${changecomment.id}/`,{
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 204) {
                // настроить удаление
                closeModal();
                updateComment();
            }
        }).catch(error =>{
            console.error(error);
        });
    };

    const linkfile = [];
    const regex = /<[^>]*>/g;
    const regextable = /&nbsp;/g;

    function getDateUpdate(updated_at) {
        const date = new Date();
        const lastUpdatedDate = new Date(updated_at.split('T')[0]);
        const differenceInMilliseconds = date.getTime() - lastUpdatedDate.getTime();
        const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
        let daysAgoString;
        //надо исправить первому показ дня
        if (differenceInDays === 0) {
            daysAgoString = 'Обновленно сегодня';
        } else if (differenceInDays === 1) {
            daysAgoString = 'Обновленно 1 день назад';
        } else if (differenceInDays % 10 === 1 && differenceInDays !== 11) {
            daysAgoString = `Обновленно ${differenceInDays} день назад`;
        } else if ((differenceInDays % 10 === 2 || differenceInDays % 10 === 3 || differenceInDays % 10 === 4) && 
            (differenceInDays < 10 || differenceInDays > 20)) {
            daysAgoString = `Обновленно ${differenceInDays} дня назад`;
        } else {
            daysAgoString = `Обновленно ${differenceInDays} дней назад`;
        }
        return daysAgoString;
    };

    const checkdubl = [];

    function deletedublicates(data) {
        if (checkdubl.includes(data)) {
            return null;
        } else {
            checkdubl.push(data);
            const namefile = data.split('/');
            return (
                <button
                    id="link-to-file"
                    onClick={() => alert("Функция временно отключена")}
                >
                    {namefile[namefile.length - 1]}
                </button>
            );
        }
    };

    const handlePostUserFromHint = (username) => {
        const atIndex = commentuser.lastIndexOf('@');
        if (atIndex !== -1) {
            const mentionText = commentuser.substring(atIndex + 1);
            if (mentionText.endsWith(username)) {
                const newCommentText = commentuser.substring(0, atIndex) + `@${username}`;
                setCommentUser(newCommentText);
            } else {
                setCommentUser(prevCommentUser => prevCommentUser + username);
                const newCommentText = commentuser.substring(0, atIndex) + `@${username}`;
                setCommentUser(newCommentText);
            }
        } else {
            setCommentUser(prevCommentUser => prevCommentUser + username);
        }
    };

    useEffect(() => {
        console.log(commentuser);
    }, [commentuser]);

    function getnumbertask(){
        if (isActiveHistory === false){
            return  (
                <div id='div-for-comments-left' style={{display: "flex", flexDirection: "column"}}>
                    <div>
                    {canViewComment ?
                    <div id="div-with-comments">
                        {comments.map(renderComment)}
                        <div style={{display: 'flex', maxWidth: '903px'}}>
                            <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                        
                            
                        </div>
                        </div>
                    </div>: null}
                    { hintselectuserselect ?
                            <div id="hint-with-all-users">
                                {hintuserfilter.map((pf, pfindex) =>
                                <div id="hint-with-users">       
                                    {pf.image ?
                                        <img id="image-performer"
                                            src={`${pf.image}`}
                                            alt=""
                                        >
                                        </img>:
                                        <img id="not-image-performer"
                                            src={apiUrlFrontend + "person-square.svg"}
                                            alt=""
                                        >
                                        </img>
                                    }
                                    <button id="select-user"
                                        onClick={() => handlePostUserFromHint(pf.username)}
                                    >
                                        {pf.username.slice(0, 42)}
                                    </button>
                                </div>)}
                            </div> : null }
                    {canAddComment ?
                            <div id='div-for-input-comm' style={{display: "flex", width: "100%", marginTop: '5px', marginLeft: '10px'}}>
                            <input id="input-for-comment-is-active"
                                onChange={handleCommentTaskChange}
                                placeholder='Введите текст комментария'
                                value={commentuser}
                                onKeyDown={(e) => handleKeyDown(e)}
                            >
                            </input>
                            <button id="send-comment"
                                onClick={handleSendCommentClick}
                            >
                                &#10148;
                            </button>
                            </div>: null}
                    </div>
                        <div id='div-for-history-and-datetime'>
                        {canViewHistoryTask ?
                        <label id="history-label-on-off"
                            style={{ background: selectedhistorys.includes(history) ? '#684689' : 'rgb(44, 45, 55)',
                                    color: selectedhistorys.includes(history) ? 'white' : 'white'
                            }}
                        >
                            <input
                                type="checkbox"
                                onChange={() => handleCheckboxChangeHistorys(history)}
                                checked={selectedhistorys.includes(history)}
                            />
                            История
                        </label>: null}
                        <label id="datetime-create-task">Дата создания {task.created_at ? format(new Date(task.created_at), 'dd.MM.yyyy hh:mm'): ''}</label>
                        </div>
                        <div id='div-btn-in-left-part'>
                       <div id="div-with-deadline">
                            <label id="label-div-right-title">Состояние</label>
                            {canChangeTask ?
                            <button id="change-task-group"
                                onClick={handleOpenGroup}
                            >
                                {group.slice(0, 12)}
                            </button>:<button id="change-task-group"
                            >
                                {group.slice(0, 12)}
                            </button>}
                        </div>
                        <div id="div-with-deadline">
                            <label id="label-div-right-title">Срок сдачи</label>
                            {canChangeTask ?
                                <button id="add-deadline-to-task"
                                    onClick={openModalCalendar}
                                >
                                    {task.deadline ? format(new Date(task.deadline), 'dd.MM.yyyy') : "Изменить"}
                                </button>: <button id="add-deadline-to-task"
                                >
                                    {task.deadline ? format(new Date(task.deadline), 'dd.MM.yyyy'): "Не назначено"}
                                </button>
                            }
                        </div>
                        <div id="div-performer-add">
                            <div style={{display: "flex", width: "100%"}}>
                                <label id="label-div-right-title">Исполнитель</label>
                                {canChangeTask ?
                                <button id="add-performer-to-task"
                                    onClick={() => openModal()}
                                >
                                    Изменить
                                </button> : null}
                            </div>
                            {task && task.performer && task.performer.map((performers, taskindex) => (
                            canViewUser ?
                            performer.filter(pf => pf.username === performers).map((k, kindex) => (
                                <div id='div-with-img-and-name-user' style={{display: "flex", justifyItems: "center"}}>
                                {k.image ?
                                    <img id="image-performer"
                                        src={k.image}
                                        alt=""
                                    >
                                    </img>:
                                    <img id="not-image-performer"
                                        src={apiUrlFrontend + "person-square.svg"}
                                        alt=""
                                    >
                                    </img>
                                }
                                {k.formated_fio ?
                                    <button id="open-profile-performer"
                                        onClick={() => handleOpenProfileClick(performers)}
                                        onMouseDown={(e) => handleGoToProfileNewPin(e, performers)}
                                    >
                                        {k.formated_fio.slice(0, 24)}
                                    </button>:
                                    <button id="open-profile-performer"
                                        onClick={() => handleOpenProfileClick(performers)}
                                        onMouseDown={(e) => handleGoToProfileNewPin(e, performers)}
                                    >
                                        {performers.slice(0, 24)}
                                    </button>
                                }
                                </div>
                            )): null
                        ))}
                        </div>
                        <div id="div-performer-add">
                            <div style={{display: "flex", width: "100%"}}>
                                <label id="label-div-right-title">Ответственный</label>
                                {canChangeTask ?
                                <button id="add-performer-to-task"
                                    onClick={() => openModalResponsible()}
                                >
                                    Изменить
                                </button> : null}
                            </div>
                            {task && task.responsible && task.responsible.map((performers, taskindex) => (
                            canViewUser ?
                            performer.filter(pf => pf.username === performers).map((k, kindex) => (
                                <div style={{display: "flex", justifyItems: 'center' }}>
                                {k.image ? 
                                    <img id="image-performer"
                                        src={k.image}
                                        alt=""
                                    >
                                    </img>:
                                    <img id="not-image-performer"
                                        src={apiUrlFrontend + "person-square.svg"}
                                        alt=""
                                    >
                                    </img>
                                }
                                {k.formated_fio ?
                                    <button id="open-profile-performer"
                                        onClick={() => handleOpenProfileClick(performers)}
                                        onMouseDown={(e) => handleGoToProfileNewPin(e, performers)} 
                                    >
                                        {k.formated_fio.slice(0, 24)}
                                    </button>:
                                    <button id="open-profile-performer"
                                        onClick={() => handleOpenProfileClick(performers)}
                                        onMouseDown={(e) => handleGoToProfileNewPin(e, performers)} 
                                    >
                                        {performers.slice(0, 24)}
                                    </button>
                                }
                                </div>
                            )):null
                        ))}
                        </div>
                        </div>  
                </div>
            )
        }
        else if (isActiveComment === false){
            return  (
                <div>
                    <div id="div-for-history-detail-task">
                            <div id="div-for-history-column">
                            {taskhistory.map((th, taskindex) => (
                                th.performer_username === "" && th.responsible_username === "" && th.label_name === "" && th.file_name === ""
                                && th.title === "" && th.description === "" && th.deadline === "" && th.priority === "" && th.group_title === "" ? null:
                                <div id="div-not-column" key={taskindex}>
                                    <div id='div-for-history-stroke'>
                                    <div id="div-for-history-name-and-dateupdate">
                                    <label id="label-username">{th.who_changed_username}</label>
                                    <label id="label-dateupdate">{getDateUpdate(th.updated_at)}</label>     
                                    </div> 
                                    <div id="div-for-main-history-information-detail-task">
                                        {th.title && <label id="text-change">
                                            <span style={{ color: 'rgb(255, 255, 255)', fontWeight: 'bold' }}>
                                                    {th.title.split(' ').slice(0, 2).join(' ')}
                                            </span>
                                            <icon id="arrow-icon">
                                                &#10140;
                                            </icon>
                                            {th.title.split(' ').slice(2).join(' ')}
                                        </label>}
                                        {th.description &&  <label id="text-change">
                                            <span style={{ color: 'rgb(255, 255, 255)', fontWeight: 'bold' }}>
                                                {th.description.split(' ').slice(0, 2).join(' ').replace(regex, '')}
                                            </span>
                                            <icon id="arrow-icon">
                                                &#10140;
                                            </icon>
                                            {th.description.split(' ').slice(2).join(' ').replace(regex, '').replace(regextable, '')}
                                        </label>}
                                        {th.performer_username && <label id="text-change">
                                            <span style={{ color: 'rgb(255, 255, 255)' , fontWeight: 'bold' }}>
                                                {th.performer_username.split(' ').slice(0, 2).join(' ') }
                                            </span>
                                            <icon id="arrow-icon">
                                                &#10140;
                                            </icon>
                                            {th.performer_username.split(' ').slice(2).join(' ')}
                                        </label>}
                                        {th.label_name && <label id="text-change">
                                        <span style={{ color: 'rgb(255, 255, 255)' , fontWeight: 'bold' }}>
                                                {th.label_name.split(' ').slice(0, 2).join(' ')}
                                            </span>
                                            <icon id="arrow-icon">
                                                &#10140;
                                            </icon>
                                            {th.label_name.split(' ').slice(2).join(' ')}
                                        </label>}
                                        {th.file_name && <div id="text-change">
                                            <span style={{ color: 'rgb(255, 255, 255)' , fontWeight: 'bold' }}>
                                                {th.file_name.split(' ').slice(0, 2).join(' ')}
                                            </span>
                                            <icon id="arrow-icon">
                                                &#10140;
                                            </icon>
                                            { th.file_name.split(' ').slice(2).join(' ') === "None" ? "None" :
                                            linkfile.push((`${apiUrl}` + (th.file_name.split(' ').slice(2).join(' ')).replace(/, /g, `, ${apiUrl}`)).split(', ')) > 0 ? null: "None"}
                                            {linkfile.map(lb => lb.map((pm, pmindex) => (
                                                deletedublicates(pm)
                                            )))}
                                        </div>}
                                        {th.deadline && <label id="text-change">
                                        <span style={{ color: 'rgb(255, 255, 255)' , fontWeight: 'bold' }}>
                                                {th.deadline.split(' ').slice(0, 4).join(' ')}
                                            </span>
                                            <icon id="arrow-icon">
                                                &#10140;
                                            </icon>
                                            {th.deadline.split(' ').slice(4).join(' ')}
                                        </label>}
                                        {th.priority && <label id="text-change">
                                        <span style={{ color: 'rgb(255, 255, 255)' , fontWeight: 'bold' }}>
                                                {th.priority.split(' ').slice(0, 2).join(' ')}
                                            </span>
                                            <icon id="arrow-icon">
                                                &#10140;
                                            </icon>
                                            {th.priority.split(' ').slice(2).join(' ')}
                                        </label>}
                                        {th.group_title && <label id="text-change">
                                        <span style={{ color: 'rgb(255, 255, 255)' , fontWeight: 'bold' }}>
                                                {th.group_title.split(' ').slice(0, 2).join(' ')}
                                            </span>
                                            <icon id="arrow-icon">
                                                &#10140;
                                            </icon>
                                            {th.group_title.split(' ').slice(2).join(' ')}
                                        </label>}
                                        {th.responsible_username && <label id="text-change">
                                            <span style={{ color: 'rgb(255, 255, 255)' , fontWeight: 'bold' }}>
                                                {th.responsible_username.split(' ').slice(0, 2).join(' ') }
                                            </span>
                                            <icon id="arrow-icon">
                                                &#10140;
                                            </icon>
                                            {th.responsible_username.split(' ').slice(2).join(' ')}
                                        </label>}
                                    </div>
                                    </div>

                                </div>      
                            ))}
                        </div>
                    </div>
                    {hintselectuserselect ?
                            <div id="hint-with-all-users">
                                {hintuserfilter.map((pf, pfindex) =>
                                <div id="hint-with-users">       
                                    {pf.image ?
                                        <img id="image-performer"
                                        src={`${pf.image}`}
                                        alt=""
                                    >
                                    </img>:
                                    <img id="not-image-performer"
                                            src={apiUrlFrontend + "person-square.svg"}
                                            alt=""
                                        >
                                        </img>}
                                    <button id="select-user"
                                        onClick={() => handlePostUserFromHint(pf.username)}
                                    >
                                        {pf.username.slice(0, 42)}
                                    </button>
                                </div>)}
                            </div> : null}
                            {canAddComment ?
                             <div id='div-for-input-comm' style={{display: "flex", width: "100%", marginTop: '5px', marginLeft: '10px'}}>
                                <input id="input-for-comment-is-active"
                                    onChange={handleCommentTaskChange}
                                    placeholder='Введите текст комментария'
                                    value={commentuser}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                >
                                </input>
                                <button id="send-comment"
                                    onClick={handleSendCommentClick}
                                >
                                    &#10148;
                                </button>
                            </div>: null}
                            <div id='div-for-history-and-datetime'>
                        {canViewHistoryTask ?
                        <label id="history-label-on-off"
                            style={{ background: selectedhistorys.includes(history) ? '#684689' : 'rgb(44, 45, 55)',
                                    color: selectedhistorys.includes(history) ? 'white' : 'white'
                            }}
                        >
                            <input
                                type="checkbox"
                                onChange={() => handleCheckboxChangeHistorys(history)}
                                checked={selectedhistorys.includes(history)}
                            />
                            История
                        </label>: null}
                        <label id="datetime-create-task">Дата создания {task.created_at ? format(new Date(task.created_at), 'dd.MM.yyyy hh:mm'): ''}</label>
                        </div>
                        <div id='div-btn-in-left-part'>
                       <div id="div-with-deadline">
                            <label id="label-div-right-title">Состояние</label>
                            {canChangeTask ?
                            <button id="change-task-group"
                                onClick={handleOpenGroup}
                            >
                                {group.slice(0, 12)}
                            </button>:<button id="change-task-group"
                            >
                                {group.slice(0, 12)}
                            </button>}
                        </div>
                        <div id="div-with-deadline">
                            <label id="label-div-right-title">Срок сдачи</label>
                            {canChangeTask ?
                                <button id="add-deadline-to-task"
                                    onClick={openModalCalendar}
                                >
                                    {task.deadline ? format(new Date(task.deadline), 'dd.MM.yyyy') : "Изменить"}
                                </button>: <button id="add-deadline-to-task"
                                >
                                    {task.deadline ? format(new Date(task.deadline), 'dd.MM.yyyy'): "Не назначено"}
                                </button>
                            }
                        </div>
                        <div id="div-performer-add">
                            <div style={{display: "flex", width: "100%"}}>
                                <label id="label-div-right-title">Исполнитель</label>
                                {canChangeTask ?
                                <button id="add-performer-to-task"
                                    onClick={() => openModal()}
                                >
                                    Изменить
                                </button> : null}
                            </div>
                            {task && task.performer && task.performer.map((performers, taskindex) => (
                            canViewUser ?
                            performer.filter(pf => pf.username === performers).map((k, kindex) => (
                                <div style={{display: "flex", justifyItems: 'center' }}>
                                {k.image ? 
                                    <img id="image-performer"
                                        src={k.image}
                                        alt=""
                                    >
                                    </img>:
                                    <img id="not-image-performer"
                                        src={apiUrlFrontend + "person-square.svg"}
                                        alt=""
                                    >
                                    </img>
                                }
                                {k.formated_fio ?
                                    <button id="open-profile-performer"
                                        onClick={() => handleOpenProfileClick(performers)}
                                        onMouseDown={(e) => handleGoToProfileNewPin(e, performers)} 
                                    >
                                        {k.formated_fio.slice(0, 24)}
                                    </button>:
                                    <button id="open-profile-performer"
                                        onClick={() => handleOpenProfileClick(performers)}
                                        onMouseDown={(e) => handleGoToProfileNewPin(e, performers)} 
                                    >
                                        {performers.slice(0, 24)}
                                    </button>
                                }
                                </div>
                            )):null
                        ))}
                        </div>
                        <div id="div-performer-add">
                            <div style={{display: "flex", width: "100%"}}>
                                <label id="label-div-right-title">Ответственный</label>
                                {canChangeTask ?
                                <button id="add-performer-to-task"
                                    onClick={() => openModalResponsible()}
                                >
                                    Изменить
                                </button> : null}
                            </div>
                            {task && task.responsible && task.responsible.map((performers, taskindex) => (
                            canViewUser ?
                            performer.filter(pf => pf.username === performers).map((k, kindex) => (
                                <div style={{display: "flex", justifyItems: 'center' }}>
                                {k.image ? 
                                    <img id="image-performer"
                                        src={k.image}
                                        alt=""
                                    >
                                    </img>:
                                    <img id="not-image-performer"
                                        src={apiUrlFrontend + "person-square.svg"}
                                        alt=""
                                    >
                                    </img>
                                }
                                {k.formated_fio ?
                                    <button id="open-profile-performer"
                                        onClick={() => handleOpenProfileClick(performers)}
                                        onMouseDown={(e) => handleGoToProfileNewPin(e, performers)} 
                                    >
                                        {k.formated_fio.slice(0, 24)}
                                    </button>:
                                    <button id="open-profile-performer"
                                        onClick={() => handleOpenProfileClick(performers)}
                                        onMouseDown={(e) => handleGoToProfileNewPin(e, performers)} 
                                    >
                                        {performers.slice(0, 24)}
                                    </button>
                                }
                                </div>
                            )):null
                        ))}
                        </div>
                        </div>  
                </div>
            )
        }
        else {
            return  (
                <div>
                    <div id="div-for-comment">
                        {canViewHistoryTask ?
                        <label id="history-label-on-off"
                            style={{ background: selectedhistorys.includes(history) ? '#684689' : 'rgb(44, 45, 55)',
                                    color: selectedhistorys.includes(history) ? 'white' : 'white'
                            }}
                        >
                            <input
                                type="checkbox"
                                onChange={() => handleCheckboxChangeHistorys(history)}
                                checked={selectedhistorys.includes(history)}
                            />
                            История
                        </label>: null}
                    </div>
                    {canViewComment ?
                    <div id="div-with-comments">
                        {comments.map(renderComment)}
                        <div style={{display: 'flex', maxWidth: '903px'}}>
                            <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                        { hintselectuserselect ?
                            <div id="hint-with-all-users">
                                {hintuserfilter.map((pf, pfindex) =>
                                <div id="hint-with-users">       
                                    {pf.image ?
                                        <img id="image-performer"
                                            src={`${pf.image}`}
                                            alt=""
                                        >
                                        </img>:
                                        <img id="not-image-performer"
                                            src={apiUrlFrontend + "person-square.svg"}
                                            alt=""
                                        >
                                        </img>
                                    }
                                    <button id="select-user"
                                        onClick={() => handlePostUserFromHint(pf.username)}
                                    >
                                        {pf.username.slice(0, 42)}
                                    </button>
                                </div>)}
                            </div> : null }
                            {canAddComment ?
                            <div style={{display: "flex", width: "100%"}}>
                            <input id="input-for-comment-is-active"
                                onChange={handleCommentTaskChange}
                                placeholder='Введите текст комментария'
                                value={commentuser}
                                onKeyDown={(e) => handleKeyDown(e)}
                            >
                            </input>
                            <button id="send-comment"
                                onClick={handleSendCommentClick}
                            >
                                &#10148;
                            </button>
                            </div>: null}
                        </div>
                        </div>
                    </div>: null}
                </div>
            )
        }
    };

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setDescriptionTask(data);
    };

    const handleOpenModalBoard = (event) => {
        event.preventDefault();
        closeModal();
        const loadBoards = async () => {
            if (loading || !hasMore) return;
            setLoading(true);
            try {
                const response = await api.get(`${apiUrl}api/v1/boards/`, {
                params: { page: page },
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            });
            if (response.status === 200) {
                setBoard(prevBoards => {
                    const newBoards = response.data.results.filter(newBoard => 
                    newBoard.id !== boardid && !prevBoards.some(prevBoard => prevBoard.id === newBoard.id));
                    return [...prevBoards, ...newBoards];
                });
                setHasMore(response.data.next != null);
            }} 
            catch (error) {
                console.error(error)
                const status = error.response ? error.response.status: null;
                if (status !== 403) {
                }
            }
        }
        loadBoards();
        setopenModalChangeBoard(true);
    };

    const handleOpenModalSubtasks = (event) => {
        event.preventDefault();
        api.get(`${apiUrl}api/v1/tasks/`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
        }).then((response) => {
            if (response.status === 200) {
                settasklist(response.data.filter(item => item.task_id !== task.task_id));
                const sortedTasks = response.data.filter(item => item.task_id !== task.task_id).sort((a, b) => {
                    if (selectedSubtasks.includes(a.task_id) && !selectedSubtasks.includes(b.task_id)) {
                      return -1;
                    } else if (!selectedSubtasks.includes(a.task_id) && selectedSubtasks.includes(b.task_id)) {
                      return 1;
                    } else {
                      return 0;
                    }
                  });
                setfiltertasklist(sortedTasks);
                closeModal();
                setModalIsOpenUniteSubtasks(true);
            }
        }).catch(error =>{
            console.error(error);
            const status = error.response ? error.response.status: null;
            if (error.response.status === 403)
            {
                alert(`Код ошибки: ${status}, ${error.response.data.detail}`, error);
            }
        });
    };

    const handleSearch = (searchitem) => {
        setIsTextSelected(false);
        if (searchitem.length >= 1) {
            const regex = new RegExp(searchitem, "i");
            const filtered = filtertasklist.filter((task) => regex.test(task.title));
            setfiltertasklist(filtered);
        } 
        else if (searchitem.length < 1){
            setfiltertasklist(tasklist);
        }
    };

    const handleCheckboxChangeSubtasks = (subtask) => {
        const split = (window.location.pathname).split('/');
        const task_pk = split[2];
        if (selectedSubtasks.includes(subtask)) {
        setselectedSubtasks(selectedSubtasks.filter((selectedsubtasks) => selectedsubtasks !== subtask));
            api.patch(`${apiUrl}api/v1/tasks/` + task_pk + '/', {
                'subtasks': selectedSubtasks.filter((selectedsubtasks) => selectedsubtasks !== subtask)
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
            if (response.status === 200) { 
                if (isActiveHistory === true){
                    updateHistory()
                }
                updateall()
            }}).catch(error => {
                console.error(error);
            });
            } 
            else {
                setselectedSubtasks([...selectedSubtasks, subtask]);
                api.patch(`${apiUrl}api/v1/tasks/` + task_pk + '/', {
                    'subtasks': [...selectedSubtasks, subtask],
                },{
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                }).then((response) => {
                if (response.status === 200) { 
                if (isActiveHistory === true){
                    updateHistory()
                }
                updateall()
                }}).catch(error => {
                    console.error(error);
            });
        }
    };

    const handlesetsearchitem = (event) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
    
        timerRef.current = setTimeout(() => {
            handleSearch(event.target.value);
        }, 500);
    };

    const handleCreateTemplate = () =>{
        const result = window.confirm("Создать шаблон задачи?");
        if (result) {
            api.post(`${apiUrl}api/v1/template-task/`, {
                'title': task.title,
                'priority': task.priority,
                'description': task.description,
                'label': `${task.label.map(lb => lb.id)}`,
                'performer': `${task.performer.map(pf => pf)}`,
                'subtasks': `${selectedSubtasks.map(sb => sb)}`,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 201) {
                    alert('Шаблон успешно сохранен!');
                }
            }).catch(error => {
                console.error(error);
            });
        }
    };

    const handleSendCommentAnswer = () => {
        const split = (window.location.pathname).split('/');
        const task_pk = split[2];
        api.post(`${apiUrl}api/v1/tasks/${task_pk}/comments/`, {
            'text': answercommenttext,
            'question_user': selectedcommentanswer.author
        },{
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            if (response.status === 201){
                setselectedcommentanswer([]);
                setanswercommenttext("");
                updateComment();
                closeModal();
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleGoToProfileNewPin = (e, username) => {
        if (e.button === 1) {
            e.preventDefault();
            window.open("/profile/" + username);
        }
    };

    const handleOpenAccessDeleteTask = (event) => {
        const result = window.confirm("ты подумай сейчас ок нажмешь и все удалится");
        if (result) {
            api.delete(`${apiUrl}api/v1/tasks/${task.task_id}/`,{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 204) {
                    closeModal();
                    alert("Успешно удалено");
                    window.location.href = "/main";
                }
            })
            .catch(error =>{
                console.error(error);
            });
        }
    };

    const handleDeleteLabelClick = (event, labeli) => {
        event.preventDefault();
        const result = window.confirm("Метка будет УДАЛЕНА со всех имеющихся задач");
        if (result) {
            api.delete(`${apiUrl}api/v1/labels/${labeli.id}/`,{
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            }).then((response) => {
                if (response.status === 204) {
                    closeModal();
                    alert("Успешно удалено");
                    updateall();
                }
            })
            .catch(error =>{
                console.error(error);
            });
        }
    };

    function handleCreateReportForTask (event, taskid) {
        event.preventDefault();
        const handleDownload = async (taskid) => {
            const response = await fetch(`${apiUrl}api/v1/tasks/${taskid}/get-report-subtask/`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/pdf',
                },
            });
           
            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'file.pdf';
                document.body.appendChild(a);
                a.click();
                a.remove();
            } else {
            console.error("Ошибка при загрузке PDF");
            }
            };
        handleDownload(taskid)
    };

    var screenHeight = window.innerHeight;

    document.title = `Задача ${task.title}- ТвояЗадача`;

    return (
        <div className='detail-task-page'
            style={{ overflowY: 'auto', whiteSpace: 'nowrap', height:"100%", width: "100%", alignItems: "center", display: "flex", flexDirection: "column"}} 
        >
            <Modal className="Modal"
                isOpen={modalIsOpenAnswerComment}
                onRequestClose={closeModal}
                overlayClassName="Overlay"
            >
                <div id="div-modal-subtasks">
                    <div id="div-for-close-and-label-modal">
                        <label id="modal-label-answercomment">Ответ пользователю: {selectedcommentanswer.author} на комментарий:</label>

                    </div>
                    <label id="answer-comment">
                        {selectedcommentanswer.text}
                    </label>
                    <input
                        id='text-answer-comment'
                        placeholder='Введите ответ'
                        onClick={handleTextareaClick}
                        onChange={(event) => setanswercommenttext(event.target.value)}
                    >
                    </input>
                    <button id="send-answer-comment" onClick={handleSendCommentAnswer}>Отправить</button>
                </div>
            </Modal>
            <Modal className="Modal"
                isOpen={modalIsOpenUniteSubtasks}
                onRequestClose={closeModal}
                overlayClassName="Overlay"
            >
                <div id="div-modal-subtasks">
                <label id="modal-label-subtasks">Добавить связь</label>
                    <div id="div-for-close-and-label-modal">
                        
                        <input
                            id='search-input-subtasks'
                            placeholder='Введите текст, чтобы найти задачи'
                            onClick={handleTextareaClick}
                            onChange={handlesetsearchitem}
                        >
                        </input>

                    </div>
                    <div id="scroll-subtask">
                        {filtertasklist.map((ts, tsindex) => (
                            <div id="div-with-subtasks-and-btn">
                                <input id="check-is-subtasks"
                                    type="checkbox"
                                    checked={selectedSubtasks.includes(ts.task_id)}
                                    onChange={() => handleCheckboxChangeSubtasks(ts.task_id)}
                                >
                                </input>
                                <button id="open-this-tasks"
                                    onClick={() => window.location.href = `${ts.task_id}`}
                                >
                                    {ts.title}           
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </Modal>
            <Modal className="Modal"
                isOpen={modalIsOpencomment}
                onRequestClose={closeModal}
                overlayClassName="Overlay"
            >
                <form onSubmit={handleSubmitChangeComment} className="modalformforcomment">
                    <div style={{overflow: 'hidden'}}>
                        <textarea
                            type="text"
                            id="titlecommentinput"
                            placeholder='Наименование колонки'
                            className="no-resize"
                            required
                            maxLength={250}
                            onChange={handledCommentChange}
                        >
                            {changecomment.text}
                        </textarea>
                        <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                        {canChangeComment ?
                        <button id="ModalSubmitcomment" type="submit">Изменить</button>: null}
                        {canDeleteComment ?
                        <button id="deleteComment" onClick={handleDeleteComment}>Удалить</button>: null}
                        </div>
                        
                    </div>
                    
                </form>
            </Modal>
            <Modal
                className="Modal"
                isOpen={modalIsOpenGetLabel}
                onRequestClose={closeModal}
                overlayClassName="Overlay"
            >
                <div id="modal-add-performer">
                    
                    <label id="performerlabel-create-task">Метка</label>
                    <div id="div-for-label-list">
                        {label.map((labeli, labelindex) => (
                            canViewLabel ? 
                                <label id="div-with-label" key={labelindex}
                                    style={{ 
                                        background: selectedlabels.includes(labeli.id) ? 'rgb(104, 70, 137)' : 'rgb(71, 72, 88)',
                                        color: selectedlabels.includes(labeli.id) ? 'white' : 'white',        
                                    }}
                                >
                                    <div style={{
                                        background: labeli.color, 
                                        height: "25px",
                                        width: "25px",
                                        borderRadius: "5px",
                                    }}>
                                    </div>
                                    <input
                                        type="checkbox"
                                        key={labelindex}
                                        name="performers"
                                        onChange={() => handleCheckboxChangeLabel(labeli.id)}
                                        checked={selectedlabels.includes(labeli.id)}
                                    />
                                    <span style={{marginLeft: "10px"}}>
                                        {labeli.name.slice(0, 14)}
                                    </span>
                                    {handledeletelabel ?
                                        <button id="delete-label-btn" onClick={(event)=> handleDeleteLabelClick(event, labeli)}>
                                            X
                                        </button>: null
                                    }
                                </label>: null
                        ))}
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <button id="open-modal-delete-label"
                            onClick={() => sethandledeletelabel(!handledeletelabel)}
                        >
                            Удалить
                        </button>
                        <button id="open-modal-create-label"
                            onClick={openModalLabel}
                        >
                            Создать
                        </button>
                    </div>                    
                </div>
            </Modal>
            <Modal
                className="Modal-add-performer"
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                overlayClassName="Overlay"
            >
                <div id="rightwindow">
                    
                    <label id="performerlabel">Исполнитель</label>
                    <div id="updivright">
                        {performer.map((performer, index) => (
                            <label id="divupforscroll" key={index}
                                style={{ background: selectedperformers.includes(performer.username) ? '#684689' : '#474858',
                                    color: selectedperformers.includes(performer.username) ? 'white' : 'white',        
                               }}
                            >
                                {performer.image ?
                                <img id="photo-profile-for-performer"
                                    src={performer.image}
                                    alt=""
                                >
                                </img>:
                                <img id="not-photo-profile-for-performer"
                                    src={apiUrlFrontend + "person-square.svg"}
                                    alt=""
                                >
                                </img>}
                                    <input
                                        type="checkbox"
                                        key={index}
                                        name="performers"
                                        onChange={() => handleCheckboxChangePerformer(performer.username)}
                                        checked={selectedperformers.includes(performer.username)}
                                    />
                                    <span style={{marginLeft: "10px"}}>
                                        {performer.formated_fio ?
                                            performer.formated_fio.slice(0, 15):
                                            performer.username.slice(0, 15)
                                        }
                                    </span>
                            </label>
                        ))}
                    </div>
                </div>
            </Modal>
            <Modal
                className="Modal-add-performer"
                isOpen={modalIsOpenResponsible}
                onRequestClose={closeModal}
                overlayClassName="Overlay"
            >
                <div id="rightwindow">
                    
                    <label id="performerlabel">Ответственный</label>
                    <div id="updivright">
                        {performer.map((performer, index) => (
                            <label id="divupforscroll" key={index}
                                style={{ background: selectedResponsibles.includes(performer.username) ? '#684689' : '#474858',
                                    color: selectedResponsibles.includes(performer.username) ? 'white' : 'white',        
                               }}
                            >
                                {performer.image ?
                                <img id="photo-profile-for-performer"
                                    src={performer.image}
                                    alt=""
                                >
                                </img>:
                                <img id="not-photo-profile-for-performer"
                                    src={apiUrlFrontend + "person-square.svg"}
                                    alt=""
                                >
                                </img>}
                                    <input
                                        type="checkbox"
                                        key={index}
                                        name="performers"
                                        onChange={() => handleCheckboxChangeResponsible(performer.username)}
                                        checked={selectedResponsibles.includes(performer.username)}
                                    />
                                    <span style={{marginLeft: "10px"}}>
                                        {performer.formated_fio ?
                                            performer.formated_fio.slice(0, 15):
                                            performer.username.slice(0, 15)
                                        }
                                    </span>
                            </label>
                        ))}
                    </div>
                </div>
            </Modal>
            <Modal
                className="Modal"
                isOpen={modalIsOpenLabel}
                onRequestClose={closeModal}
                overlayClassName="Overlay"
            >
                <div id="rightwindow">
               
                    <label id="performerlabel-create-task">Создание метки</label>
                    <label id="hint-new-name-label">Название</label>
                    <input id="new-name-label"
                        onChange={handleChangeNameLabel}
                    >
                    </input>
                    <label id="hint-for-color-label">Цвет</label>
                    <div style={{ width: '240px', display: 'flex', flexWrap: 'wrap', borderRadius: "10px", background: "none", justifyContent: 'center' }}>
                        {webcolors.map((color, index) => (
                            <div key={index} style={{border: selectedColor === color ? '2px Solid white': 'none', borderRadius: '15px'}}
                                onClick={() => setSelectedColor(color)}
                            >
                                <div style={{ backgroundColor: color, height: selectedColor === color ? '26px': '30px', margin: '4px', cursor: 'pointer', borderRadius: '10px', width: selectedColor === color ? '46px': '50px'}}>               
                                </div>
                            </div>
                        ))}
                    </div>
                    <button id="create-label"
                        onClick={handleCreateLabel}
                    >
                        Создать
                    </button>
                </div>
            </Modal>
            <Modal
                className="Modal"
                isOpen={openModalChangeGroup}
                onRequestClose={closeModal}
                overlayClassName="Overlay"
            >
                <div id="rightwindow">
               
                <label id="performerlabel">Выберите состояние</label>
                    <div id="updivright">
                        {groups.filter(gr => gr.board_id === boardid).map((groups, index) => (
                                <label id="divupforscroll"
                                    key={index}
                                >
                                    <input
                                        type="checkbox"
                                        key={index}
                                        name="performers"
                                        onChange={() => updateTaskGroup(groups.group_id)}
                                    />
                                    {groups.title.slice(0, 15)}
                                </label>
                        ))}
                    </div>
                </div>
            </Modal>
            <div id="div-for-all-detail"
                style={{ overflowY: 'auto', whiteSpace: 'nowrap'}} >
                <div id="for-task-history-detail-up">
                {isactiveupdatetasks ? 
                    <textarea
                    type="text"
                    id="titlefortasksdetail"
                    placeholder='Заголовок'
                    required
                    maxLength={250}
                    value={titletask}
                    onClick={handleTextareaClick}
                    onChange={handleTitleTaskChange}
                />
                    :
                    <textarea
                    type="text"
                    id="titlefortasksdetail"
                    placeholder='Заголовок'
                    required
                    maxLength={250}
                    value={titletask}
                    onClick={handleTextareaClick}
                    readOnly
                    />
                }
                <div id='div-btn-edit-task'>
                    <div id="div-with-title-and-subtask">
                        <button id="create-template"
                            onClick={() => handleCreateTemplate()}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Создать шаблон задачи"
                        >
                            <img id='pattern-btn-id' src={apiUrlFrontend + 'pattern-btn.svg'}></img>
                        </button>
                        {canChangeComment ?
                        <button id="update-title-task"
                            onClick={() => handleUpdateTitleDescTask()}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Редактировать задачу"
                        >
                            <img id='edit-btn-id' src={apiUrlFrontend + 'edit-btn.svg'}></img>
                        </button>: null}
                    </div>
                    <div id="div-with-create-template">
                        <button id="update-subtasks-task"
                            onClick={handleOpenModalSubtasks}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Добавить связанную задачу"
                        >
                            <img src={apiUrlFrontend + 'connect-btn.svg'}></img>
                        </button>
                        {canDeleteTask ? 
                        <button id="update-subtasks-task"
                            onClick={() => handleOpenAccessDeleteTask()}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Удалить задачу"
                        >
                            <img id='pattern-btn-id' src={apiUrlFrontend + 'delete-btn.svg'}></img>
                        </button>: null}
                        {isactiveupdatetasks ?
                        <button id="update-desc-task"
                            onClick={() => handleUpdateDescTask()}
                        >
                            Сохранить
                        </button>: null}
                    </div>
                </div>
                
                    {canViewUser ?
                    <div style={{display: "flex", marginLeft: 'auto'}}>
                    <label id="label-author">Автор :</label>
                    {task.formated_author_fio ?
                    <button id="button-author"
                        onClick={() => handleOpenProfileClick(task.author)}
                    >
                        {task.formated_author_fio}
                    </button>:
                    <button id="button-author"
                        onClick={() => handleOpenProfileClick(task.author)}
                    >
                        {task.author}
                    </button>}
                    </div>: null}
                    {performer.filter(pf => pf.username === task.author).map((k, kindex) => ( 
                        k.image ?
                        <img id="image-author"
                            src={k.image}
                            alt=""
                        >
                        </img>: 
                        <img id="image-author"
                            src={apiUrlFrontend + "person-square.svg"}
                            alt=""
                        >
                        </img>
                    ))}
                </div>
                <div id="div-for-left-and-right">
                    <div id="for-task-history-detail-left">
                        <div id="div-for-desc-and-update-btn">
                        {isactiveupdatetasks ?
                            <div style={{background: "#474858"}}>
                                <CKEditor
                                    extraAllowedContent='*(*);*{*}'
                                    editor={ ClassicEditor }
                                    disabled={false}
                                    data={descriptiontask}
                                    config={
                                        editorConfig
                                    }
                                    onChange={ ( event, editor ) => {
                                        handleEditorChange(event, editor)
                                    }}
                                />
                            </div>
                        :
                            <CKEditor 
                                editor={ ClassicEditor }
                                extraAllowedContent='*(*);*{*}'
                                data={descriptiontask}
                                disabled={true}
                                config={
                                    editorConfig
                                }
                            />
                        }
                            {task.subtasks ?
                                task.subtasks.length > 0 ?
                                    activeSeeSubtasks ? 
                                    <div id="div-related-tasks" onClick={() => setActiveSeeSubtasks(!activeSeeSubtasks)}>
                                        <label id="related-task-label">
                                            Связанные задачи  &#9660;
                                        </label>
                                    </div>:
                                    <div id="div-related-tasks" onClick={(event) => handleOpenRelatedTask(event, task.task_id)}>
                                        <label id="related-task-label">
                                            Связанные задачи  &#9660;
                                        </label>
                                    </div>: null: null
                            }
                            {activeSeeSubtasks ?
                                <div id="div-with-mini-card-related">
                                    {relatedSubtasks.map((ts, tsindex) => (
                                        <button id="mini-card-detail-task" key={tsindex}
                                        onClick={(e) => handleGoToRelatedTask(ts.task_id, e)}
                                        onMouseDown={(e) => handleClickGoToRelatedTaskNewPin(ts.task_id, e)}
                                    >
                                        <label id="mini-card-task-title">
                                            {ts.title}
                                        </label>
                                        {ts.system_status_group !== 'Completed' ?
                                        <div id="mini-card-div-with-deadline-and-priority">
                                            {ts.deadline ?
                                            <label id="mini-card-task-deadline">
                                                {format(new Date(ts.deadline), 'dd.MM.yyyy')}
                                            </label>: null}
                                            <label style={{marginLeft: "auto", 
                                                color: ts.priority <=3 ? 'rgba(100, 120, 214, 1.00)':
                                                        ts.priority <=7 ? 'rgba(220, 136, 0, 1.00)':
                                                        'rgba(175, 52, 52, 1.00)'}}>
                                                &#9733;
                                            </label>
                                        </div>: 
                                        <div id="mini-card-completed">
                                        </div>}
                                    </button>
                                    ))}
                                </div>: null
                            }
                            <div style={{display: 'flex', alignItems: 'center', marginTop: '10px', marginBottom: '10px', width: '100%'}}>
                                <label style={{marginLeft: '10px', color: 'white', fontFamily: 'sans-serif', fontWeight: '600'}}>
                                    Прикрепить файл
                                </label>
                                {canChangeTask ?
                                    <div {...getRootProps()}>
                                        <input id="add-file-to-task" {...getInputProps()} />
                                        <div
                                            id="add-file-to-task"
                                        >
                                            Добавить
                                        </div>
                                    </div>: null
                                }
                                <button id="create-related-task" onClick={(event) => handleOpenModalCreateRelatedTask(event)}>
                                    Создать связанную задачу
                                </button>
                                {task.subtasks ?
                                    task.subtasks.length > 0 ?
                                <button id="create-report-subtask"
                                    onClick={(event) => handleCreateReportForTask(event, task.task_id)}
                                >
                                    Сформировать отчет
                                </button>: null: null}
                                {openviewfile ?
                                    <button id='open-view-file' onClick={(event) => setopenviewfile(!openviewfile)}>
                                        Файлы &#9650;
                                    </button>
                                    :
                                    <button id='open-view-file' onClick={(event) => setopenviewfile(!openviewfile)}>
                                        Файлы &#9660;
                                    </button>
                                }
                            </div>
                            {openviewfile ?
                            <div id="div-task-file">
                            {task && task.file && task.file.map((ts, taskindex) => (
                                ts.file === null ? null:
                                <UserFile
                                    url={ts.file}
                                    task={ts}
                                    updateall={() => updateall()}
                                    task_id={task.task_id}
                                />
                            ))}
                            </div>: null}
                            
                        </div>
                        <div id="for-task-history-detail-right">
                        <div id='div-for-label-and-priority'>
                        <div id='div-for-label-and-colors'>
                        <div id="div-label-add">
                            <label id="label-div-right-title">Метка</label>
                            {canChangeTask ?
                            <button id="add-label-to-task"
                                onClick={openModalGetLabel}
                            >
                                Изменить
                            </button>:null}
                        </div>
                        <div id='div-for-label-and-color'> 
                        {task && task.label && task.label.map((task, taskindex) => (
                        canViewLabel ?
                        <div id="div-with-label" key={taskindex} style={{marginLeft: "5px", cursor: "default"}}>
                            <div style={{
                                background: task.color, 
                                height: "25px", 
                                width: "25px",
                                borderRadius: "5px",
                            }}></div>
                            <label id="label-name">{task.name}</label>
                        </div>: null
                        ))}

                        </div>
                        </div>
                        <div id="div-with-priority">
                            <label id="label-div-right-title">Приоритет</label>
                                <div className="App" style={{marginLeft: "10px"}}>
                                        {[...Array(totalStars)].map((_, index) => {
                                            const currentRating = index + 1;
                                            return (
                                            <label key={index}>
                                                <input
                                                key={currentRating}
                                                type="radio"
                                                name="rating"
                                                value={currentRating}
                                                onChange={() => handleChangePriorityTask(currentRating)}
                                                />
                                                <div
                                                className="rating-item"
                                                style={{
                                                    color: currentRating <= (hover || rating) ? "white" : "rgb(138, 135, 153)",
                                                    background: currentRating <= (hover || rating) ? "rgb(104, 70, 137)" : "rgb(84, 85, 104)"
                                                }}
                                                    onMouseEnter={() => setHover(currentRating)} 
                                                    onMouseLeave={() => setHover(null)}
                                                >
                                                {currentRating}
                                                </div>
                                            </label>
                                            );
                                        })}
                                </div>
                                <button id="change-board" onClick={(event) => handleOpenModalBoard(event)}>
                                    Сменить доску
                                </button>
                            </div>
                        </div>
                        
                        
                        
                        <div id="gorizontal-line-for-right-div"/>
                        <div id="div-label-add">
                            
                            {/* {canChangeTask ?
                            <button id="add-rating-to-task"
                                onClick={handleUpdatePriorityClick}
                            >
                                Обновить
                            </button>: null} */}
                        </div>
                        
                        

                        <Modal
                            className="Modal-calendar"
                            isOpen={modalIsOpenCalendar}
                            onRequestClose={closeModal}
                            overlayClassName="Overlay"
                        >   
                            <div id="div-calendar">
                            
                                <Calendar  onChange={(date) => {
                                    onChangeCalendar(date);
                                    handleUpdateTaskDeadline(date);
                                }}  value={value} />
                            </div>
                        </Modal>
                        <Modal
                            className="Modal"
                            isOpen={openModalChangeBoard}
                            onRequestClose={closeModal}
                            overlayClassName="Overlay"
                        >   
                                <div id="up-modal-window">
                                {/* <button id="CloseModalTask" onClick={closeModal}>X</button> */}
                                <label id="select-board-label">Выберите доску</label>
                                    <div id="scroll-Y-div">
                                        {board.length > 0 ? board.map((item, index) => (
                                            <button id="select-board" key={index} onClick={(event) => handleOpenChangeGroup(event, item)}>
                                                {item.title}
                                            </button>
                                )): null}
                                </div>
                            </div>
                        </Modal>
                        <Modal
                            className="Modal"
                            isOpen={openModalChangeGroupTask}
                            onRequestClose={closeModal}
                            overlayClassName="Overlay"
                        >   
                            <div id="up-modal-window">
                                {/* <button id="CloseModalTask" onClick={closeModal}>X</button> */}
                                <label id="select-board-label">Выберите группу</label>
                                    <div id="scroll-Y-div">
                                        {changegroup.length > 0 ? changegroup.map((item, index) => (
                                            <button id="select-board" key={index} onClick={(event) => handlechangeboardgrouptask(event, item)}>
                                                {item.title}
                                            </button>
                                        )): null}
                                    </div>
                            </div>
                        </Modal>
                        <Modal
                            className="Modal"
                            isOpen={openModalCreateRelatedTask}
                            onRequestClose={closeModal}
                            overlayClassName="Overlay"
                        >   
                            <div id="up-modal-window">
                                {/* <button id="CloseModalTask" onClick={closeModal}>X</button> */}
                            </div>
                        </Modal>

                        </div>
                    </div>
                    {getnumbertask()}
                    <Tooltip 
                        id="my-tooltip"
                        delayShow={750}
                        delayHide={100}
                    />
                </div>
                
            </div>

        </div>
    );
}

export default DetailTask;